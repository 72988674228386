@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
    --vz-modal-width: 800px;
    --vz-vertical-menu-bg: #fff;
    --vz-vertical-menu-item-color: #6d7080;
    --vz-vertical-menu-item-hover-color: #405189;
    --vz-vertical-menu-item-active-color: #405189;
    --vz-vertical-menu-sub-item-color: #7c7f90;
    --vz-vertical-menu-sub-item-hover-color: #405189;
    --vz-vertical-menu-sub-item-active-color: #405189;
    --vz-vertical-menu-title-color: #919da9;
    --vz-vertical-menu-bg-dark: #405189;
    --vz-vertical-menu-item-color-dark: #abb9e8;
    --vz-vertical-menu-item-hover-color-dark: #fff;
    --vz-vertical-menu-item-active-color-dark: #fff;
    --vz-vertical-menu-sub-item-color-dark: #abb9e8;
    --vz-vertical-menu-sub-item-hover-color-dark: #fff;
    --vz-vertical-menu-sub-item-active-color-dark: #fff;
    --vz-vertical-menu-title-color-dark: #838fb9;
    --vz-header-bg: #fff;
    --vz-header-item-color: #e9ecef;
    --vz-header-bg-dark: #405189;
    --vz-header-item-color-dark: #b0c4d9;
    --vz-header-item-sub-color: #878a99;
    --vz-header-height: 70px;
    --vz-footer-height: 60px;
    --vz-topbar-search-bg: #f3f3f9;
    --vz-topbar-user-bg: #f3f3f9;
    --vz-topbar-user-bg-dark: #52639c;
    --vz-footer-bg: #fff;
    --vz-footer-color: #98a6ad;
    --vz-topnav-bg: #fff;
    --vz-topnav-item-color: #6d7080;
    --vz-topnav-item-color-active: #405189;
    --vz-twocolumn-menu-iconview-bg: #fff;
    --vz-twocolumn-menu-bg: #fff;
    --vz-twocolumn-menu-iconview-bg-dark: var(--vz-vertical-menu-bg-dark);
    --vz-twocolumn-menu-bg-dark: #435590;
    --vz-twocolumn-menu-item-color-dark: var(--vz-vertical-menu-item-color-dark);
    --vz-twocolumn-menu-item-active-color-dark: #fff;
    --vz-twocolumn-menu-item-active-bg-dark: rgba(255, 255, 255, 0.15);
    --vz-boxed-body-bg: #e5e5f2;
    --vz-heading-color: #495057;
    --vz-link-color: #405189;
    --vz-link-hover-color: #405189;
    --vz-border-color: #e9ebec;
    --vz-card-bg-custom: #fff;
    --vz-card-logo-dark: block;
    --vz-card-logo-light: none;
    --vz-list-group-hover-bg: #f3f6f9;
    --vz-input-bg: #fff;
    --vz-input-border: #ced4da;
    --vz-input-focus-border: #a0a8c4;
    --vz-input-disabled-bg: #eff2f7;
    --vz-input-group-addon-bg: #eff2f7;
    --vz-input-check-border: var(--vz-input-border);
    --vz-shadow: 0 0 #0000;
    --vz-shadow-colored: 0 0 #0000;
    --vz-ring-shadow: 0 0 #0000;
    --vz-ring-offset-shadow: 0 0 #0000;
    --vz-ring-color: rgba(59, 130, 246, 0.5);
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --secondary: 210 40% 96.1%;
    --vz-primary-bg-subtle: #e2e5ed;
    --vz-secondary-bg-subtle: #e1ebfd;
    --vz-success-bg-subtle: #daf4f0;
    --vz-info-bg-subtle: #dff0fa;
    --vz-warning-bg-subtle: #fef4e4;
    --vz-danger-bg-subtle: #fde8e4;
    --vz-light-bg-subtle: #f9fbfc;
    --vz-dark-bg-subtle: #e9ebec;
    --vz-primary-border-subtle: #b3b9d0;
    --vz-secondary-border-subtle: #aec9f9;
    --vz-success-border-subtle: #9de1d7;
    --vz-info-border-subtle: #a9d7f1;
    --vz-warning-border-subtle: #fce3b7;
    --vz-danger-border-subtle: #f9c1b6;
    --vz-light-border-subtle: #eff2f7;
    --vz-dark-border-subtle: #adb5bd;
    --vz-input-bg-custom: #fff;
    --vz-input-border-custom: #ced4da;
    --item-hover-bg: #ebedf3;
    --item-is-active-bg: #f3f6f9;
    --vz-cdate-bg: #f1f1f1;
    --vz-cdate-border: #ddd;
    --vz-cdate-text: #666;
    --vz-cdate-diff: rgba(77, 69, 69, 0.12);
    --vz-secondary-bg: #fff;
    --vz-light-rgb: 243, 246, 249;
    --vz-header-modal-bg: #405189;
    --vz-primary-rgb-checkpoint: var(--vz-primary-rgb)
}
html {
	-webkit-tap-highlight-color: transparent;
	height: 100%;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
}
html.dark {
	color-scheme: dark;
}
.cloak {
	display: none !important;
}
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f8fafc;
	color: #64748b;
	font-family: Inter, sans-serif;
	font-size: 0.875rem;
	line-height: 1.25rem;
	text-rendering: optimizeLegibility;
}
:is(.dark body) {
	background-color: #192132;
	color: #a3adc2;
	color-scheme: dark;
}
body.is-monochrome:before {
	--tw-backdrop-grayscale: grayscale(100%);
	--tw-backdrop-opacity: opacity(0.92);
	--tw-content: "";
	-webkit-backdrop-filter: var(--tw-backdrop-blur)
		var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
		var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
		var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
		var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
		var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
		var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
		var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
		var(--tw-backdrop-sepia);
	content: var(--tw-content);
	height: calc(100% + 10rem);
	inset: 0;
	margin: -5rem;
	pointer-events: none;
	position: fixed;
	width: calc(100% + 10rem);
	z-index: 999999;
}
.app-preloader .app-preloader-inner:after,
.app-preloader .app-preloader-inner:before {
	--tw-content: "";
	background-color: rgba(203, 213, 225, 0.25);
	border-radius: 9999px;
	content: var(--tw-content);
	height: 100%;
	position: absolute;
	width: 100%;
}
:is(.dark .app-preloader .app-preloader-inner):after,
:is(.dark .app-preloader .app-preloader-inner):before {
	background-color: rgba(105, 122, 155, 0.5);
}
.app-preloader .app-preloader-inner:after,
.app-preloader .app-preloader-inner:before {
	animation: spinner-grow 3s cubic-bezier(0.55, 0.15, 0.45, 0.85) infinite;
}
.app-preloader .app-preloader-inner:after {
	animation-delay: -1.5s;
}
.sidebar-toggle span {
	--tw-translate-x: 0px;
	background-color: currentColor;
	height: 2px;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transition-duration: 0.15s;
	transition-duration: 0.25s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	width: 1.25rem;
}
.sidebar-toggle span:nth-child(2) {
	width: 0.75rem;
}
.is-sidebar-open .sidebar-toggle span {
	--tw-translate-x: -0.5rem;
	margin-left: 0.5rem;
	width: 11px;
}
.is-sidebar-open .sidebar-toggle span,
.is-sidebar-open .sidebar-toggle span:first-child {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.is-sidebar-open .sidebar-toggle span:first-child {
	--tw-rotate: -45deg;
}
.is-sidebar-open .sidebar-toggle span:nth-child(2) {
	display: none;
}
.is-sidebar-open .sidebar-toggle span:nth-child(3) {
	--tw-rotate: 45deg;
}
.is-sidebar-open .sidebar-toggle span:nth-child(3),
.main-sidebar {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.main-sidebar {
	--tw-translate-x: -100%;
	flex-shrink: 0;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transition-duration: 0.2s;
	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
	width: var(--main-sidebar-width);
	z-index: 40;
}
@media (min-width: 768px) {
	.main-sidebar {
		--tw-translate-x: 0px;
		transform: translate(var(--tw-translate-x), var(--tw-translate-y))
			rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
			skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
			scaleY(var(--tw-scale-y));
		z-index: 60;
	}
}
.is-sidebar-open .main-sidebar {
	--tw-translate-x: 0px;
}
.is-sidebar-open .main-sidebar,
.sidebar-panel {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.sidebar-panel {
	--tw-translate-x: -100%;
	--tw-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
	--tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transition-delay: 0.15s;
	transition-duration: 0.15s;
	transition-duration: 0.25s;
	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
	width: calc(var(--main-sidebar-width) + var(--sidebar-panel-width));
	z-index: 30;
}
:is(.dark .sidebar-panel) {
	--tw-shadow-color: rgba(25, 33, 50, 0.6);
	--tw-shadow: var(--tw-shadow-colored);
}
@media (min-width: 768px) {
	.sidebar-panel {
		transition-delay: unset;
	}
}
.is-sidebar-open .sidebar-panel {
	--tw-translate-x: 0px;
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.is-sidebar-open .sidebar-panel,
.sidebar-panel-min {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.sidebar-panel-min {
	--tw-translate-x: -100%;
	--tw-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
	--tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	flex-shrink: 0;
	height: 100%;
	position: fixed;
	transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transition-duration: 0.15s;
	transition-duration: 0.25s;
	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
	width: var(--sidebar-panel-min-width);
	z-index: 40;
}
:is(.dark .sidebar-panel-min) {
	--tw-shadow-color: rgba(25, 33, 50, 0.6);
	--tw-shadow: var(--tw-shadow-colored);
}
@media (min-width: 768px) {
	.sidebar-panel-min {
		--tw-translate-x: var(--main-sidebar-width);
		transform: translate(var(--tw-translate-x), var(--tw-translate-y))
			rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
			skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
			scaleY(var(--tw-scale-y));
	}
}
.is-sidebar-open .sidebar-panel-min {
	--tw-translate-x: -100%;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
nav.header {
	border-bottom-width: 1px;
	border-color: #e9eef5;
	display: flex;
	height: 61px;
	position: fixed;
	right: 0;
	top: 0;
	transition-duration: 0.15s;
	transition-duration: 0.25s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	width: 100%;
	z-index: 20;
}
:is(.dark nav.header) {
	border-color: #26334d;
}
@media (min-width: 768px) {
	nav.header {
		width: calc(100% - var(--main-sidebar-width));
	}
}
nav.header:before {
	--tw-content: "";
	background-color: #fff;
	content: var(--tw-content);
	height: 100%;
	left: calc(-100vw - -100%);
	position: absolute;
	width: calc(100vw - 100%);
}
:is(.dark nav.header):before {
	background-color: #222e45;
}
nav.header .header-container {
	padding-left: var(--margin-x);
	padding-right: var(--margin-x);
	transition-duration: 0.15s;
	transition-duration: 0.25s;
	transition-property: padding, width;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media (min-width: 768px) {
	.has-min-sidebar nav.header {
		width: calc(
			100% - var(--main-sidebar-width) - var(--sidebar-panel-min-width)
		);
	}
}
@media (min-width: 1280px) {
	.is-sidebar-open nav.header {
		width: calc(
			100% - var(--main-sidebar-width) - var(--sidebar-panel-width)
		);
	}
}
.is-header-blur nav.header .header-container {
	--tw-backdrop-blur: blur(8px);
	-webkit-backdrop-filter: var(--tw-backdrop-blur)
		var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
		var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
		var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
		var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
		var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
		var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
		var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
		var(--tw-backdrop-sepia);
}
@supports (
	(-webkit-backdrop-filter: var(--tw)) or (backdrop-filter: var(--tw))
) {
	.is-header-blur nav.header .header-container {
		background-color: hsla(0, 0%, 100%, 0.8);
	}
	:is(.dark .is-header-blur nav.header .header-container) {
		background-color: rgba(34, 46, 69, 0.8) !important;
	}
}
.is-header-not-sticky nav.header {
	position: absolute;
}
.darkmode-toggle svg {
	animation: darkmode-btn 0.2s linear forwards;
	transform-origin: top;
}
.darkmode-toggle svg.darkmode-moon {
	display: none;
}
:is(.dark .darkmode-toggle svg.darkmode-moon) {
	display: block;
}
.darkmode-toggle svg.darkmode-sun {
	display: block;
}
:is(.dark .darkmode-toggle svg.darkmode-sun) {
	display: none;
}
@keyframes darkmode-btn {
	0% {
		transform: scale(0.75);
	}
	to {
		transform: scale(1);
	}
}
.mobile-searchbar {
	animation: mobile-searchbar 0.15s cubic-bezier(0, 0, 0.2, 1) forwards;
}
.mobile-searchbar.animate\:leave {
	animation: mobile-searchbar-leave 0.15s cubic-bezier(0, 0, 0.2, 1) forwards;
}
@keyframes mobile-searchbar {
	0% {
		opacity: 0;
		transform: scale(1.05);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes mobile-searchbar-leave {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	to {
		opacity: 0;
		transform: scale(0.95);
	}
}
.main-content {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	margin-top: 60px;
	place-content: start;
	transition-duration: 0.15s;
	transition-duration: 0.25s;
	transition-property: width, margin-left, margin-right, padding-left,
		padding-right;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
@media print {
	.main-content {
		margin: 0;
	}
}
@media (min-width: 768px) {
	.main-content {
		margin-left: var(--main-sidebar-width);
	}
	.has-min-sidebar .main-content {
		margin-left: calc(
			var(--main-sidebar-width) + var(--sidebar-panel-min-width)
		);
	}
}
.is-sidebar-open .main-content {
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
@media (min-width: 1280px) {
	.is-sidebar-open .main-content {
		margin-left: calc(
			var(--main-sidebar-width) + var(--sidebar-panel-width)
		);
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.right-sidebar .right-sidebar-overlay {
	animation: fade-in 0.2s cubic-bezier(0, 0, 0.2, 1) forwards;
}
@keyframes fade-out {
	0% {
		opacity: 1;
		visibility: visible;
	}
	to {
		opacity: 0;
		visibility: hidden;
	}
}
.right-sidebar .right-sidebar-overlay.animate\:leave {
	animation: fade-out 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
.right-sidebar .right-sidebar-content {
	animation: right-sidebar-open 0.2s cubic-bezier(0, 0, 0.2, 1) forwards;
}
.right-sidebar .right-sidebar-content.animate\:leave {
	animation: right-sidebar-close 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
@keyframes right-sidebar-open {
	0% {
		transform: translate3d(100%, 0, 0);
	}
	to {
		transform: translateZ(0);
	}
}
@keyframes right-sidebar-close {
	0% {
		transform: translateZ(0);
	}
	to {
		transform: translate3d(100%, 0, 0);
	}
}
.navigation\:sideblock {
	--sideblock-width: 260px;
}
.navigation\:sideblock .sidebar-panel {
	--tw-translate-x: -100%;
	width: var(--sideblock-width);
}
.navigation\:sideblock .sidebar-panel,
.navigation\:sideblock.is-sidebar-open .sidebar-panel {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.navigation\:sideblock.is-sidebar-open .sidebar-panel {
	--tw-translate-x: 0px;
}
.navigation\:sideblock nav.header {
	width: 100%;
}
.navigation\:sideblock.is-sidebar-open nav.header {
    width: calc(100% - var(--sideblock-width));
}
.navigation\:sideblock .main-content {
	margin-left: 0;
}
.navigation\:sideblock.is-sidebar-open .main-content {
    margin-left: var(--sideblock-width);
}
.navigation\:horizontal nav.header {
	width: 100%;
}
@media (min-width: 640px) {
	.navigation\:horizontal nav.header {
		height: auto;
	}
}
.navigation\:horizontal .main-content {
	margin-left: 0;
}
@media (min-width: 640px) {
	.navigation\:horizontal .main-content {
		margin-top: 7rem;
	}
}
.h-100vh {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}
.min-h-100vh {
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
}
.inline-space > :not([hidden]) {
	margin-bottom: 0.625rem;
	margin-right: 0.625rem;
}
code.inline-code {
	background-color: #f1f5f9;
	border-radius: 0.25rem;
	color: #f000b9;
	font-size: 0.8125rem;
	line-height: 1.125rem;
	padding: 0.125rem 0.25rem;
}
:is(.dark code.inline-code) {
	background-color: #202b40;
	color: #ff57d8;
}
.is-scrollbar-hidden {
	scrollbar-width: none;
}
.is-scrollbar-hidden::-webkit-scrollbar {
	display: none;
}
@supports selector(::-webkit-scrollbar) {
	.scrollbar-sm::-webkit-scrollbar {
		height: 0.375rem;
		width: 0.375rem;
	}
	.scrollbar-sm:focus-within::-webkit-scrollbar-thumb,
	.scrollbar-sm:hover::-webkit-scrollbar-thumb {
		background-color: rgba(203, 213, 225, 0.8);
		border-radius: 9999px;
	}
	:is(.dark .scrollbar-sm:focus-within)::-webkit-scrollbar-thumb,
	:is(.dark .scrollbar-sm:hover)::-webkit-scrollbar-thumb {
		background-color: #5c6b8a;
	}
	.scrollbar-sm::-webkit-scrollbar-thumb:hover {
		background-color: #94a3b8;
	}
	:is(.dark .scrollbar-sm)::-webkit-scrollbar-thumb:hover {
		background-color: #697a9b;
	}
}

.card {
	--tw-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
	--tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
	background-color: #fff;
	border-radius: 0.5rem;
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	color: #64748b;
	display: flex;
	flex-direction: column;
	min-width: 1px;
	overflow-wrap: break-word;
	position: relative;
}
:is(.dark .card) {
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
	background-color: #26334d;
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	color: #a3adc2;
}
@media print {
	.card {
		border-width: 1px;
	}
}
.skeleton {
	--sk-color: #ffffff80;
	overflow: hidden;
	position: relative;
}
:is(.dark .skeleton) {
	--sk-color: #5c6b8a;
}
.skeleton.animate-wave:after {
	--tw-content: "";
	animation: skeleton-wave 1.5s ease-in-out 0.5s infinite;
	background: linear-gradient(
		90deg,
		transparent,
		var(--sk-color),
		transparent
	);
	content: var(--tw-content);
	inset: 0;
	position: absolute;
}
@keyframes skeleton-wave {
	0% {
		transform: translateX(-100%);
	}
	50%,
	to {
		transform: translateX(100%);
	}
}
.avatar {
	display: inline-flex;
	flex-shrink: 0;
	height: 2.5rem;
	position: relative;
	width: 2.5rem;
}
.avatar > img {
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
}
.avatar > .is-initial,
.avatar > img {
	height: 100%;
	width: 100%;
}
.avatar > .is-initial {
	align-items: center;
	display: flex;
	font-weight: 500;
	justify-content: center;
}
.spinner.is-grow span {
	animation: spinner-grow 1.5s ease-in-out infinite;
}
.spinner.is-elastic {
	animation-duration: 1.3;
	animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
}
.spinner.is-grow span:first-child {
	animation-delay: -0.75s;
}
@keyframes spinner-grow {
	0%,
	to {
		transform: scale(0);
	}
	50% {
		transform: scale(1.1);
	}
}
.popper-root {
	visibility: hidden;
	z-index: 1000;
}
.popper-root.show {
	visibility: visible;
}
.popper-root > .popper-box {
	color: #64748b;
	opacity: 0;
	transition-duration: 0.2s;
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
:is(.dark .popper-root > .popper-box) {
	color: #a3adc2;
}
.popper-root > .popper-box {
	transition-property: transform, visibility, opacity;
}
.popper-root.show > .popper-box {
	opacity: 1;
	transform: translate(0) !important;
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.popper-root[data-popper-placement^="right"] > .popper-box {
	--tw-translate-x: 0.75rem;
}
.popper-root[data-popper-placement^="bottom"] > .popper-box,
.popper-root[data-popper-placement^="right"] > .popper-box {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.popper-root[data-popper-placement^="bottom"] > .popper-box {
	--tw-translate-y: 0.75rem;
}
.popper-root[data-popper-placement^="top"] > .popper-box {
	--tw-translate-y: -0.75rem;
}
.popper-root[data-popper-placement^="left"] > .popper-box,
.popper-root[data-popper-placement^="top"] > .popper-box {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.popper-root[data-popper-placement^="left"] > .popper-box {
	--tw-translate-x: -0.75rem;
}
.popper-root[data-popper-placement^="top"] [data-popper-arrow] {
	bottom: 0.25rem;
}
.popper-root[data-popper-placement^="top"] [data-popper-arrow] > svg {
	--tw-rotate: 180deg;
	top: 0.875rem;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.popper-root[data-popper-placement^="bottom"] [data-popper-arrow] {
	top: 0.25rem;
}
.popper-root[data-popper-placement^="bottom"] [data-popper-arrow] > svg {
	bottom: 0.875rem;
}
.popper-root[data-popper-placement^="left"] [data-popper-arrow] {
	right: 0.125rem;
}
.popper-root[data-popper-placement^="left"] [data-popper-arrow] svg {
	left: 13px;
	top: calc(50% - 5px);
	transform: rotate(90deg);
}
.popper-root[data-popper-placement^="right"] [data-popper-arrow] {
	left: 0.125rem;
}
.popper-root[data-popper-placement^="right"] [data-popper-arrow] svg {
	right: 13px;
	top: calc(50% - 5px);
	transform: rotate(-90deg);
}
.form-checkbox,
.form-checkbox:before,
.form-input,
.form-multiselect,
.form-radio,
.form-radio:before,
.form-select,
.form-switch,
.form-switch:before,
.form-textarea {
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.form-input,
.form-multiselect,
.form-select,
.form-textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	letter-spacing: 0.025em;
	outline: 2px solid transparent;
	outline-offset: 2px;
}
.form-input::-moz-placeholder,
.form-multiselect::-moz-placeholder,
.form-select::-moz-placeholder,
.form-textarea::-moz-placeholder {
	font-weight: 300;
}
.form-input::placeholder,
.form-multiselect::placeholder,
.form-select::placeholder,
.form-textarea::placeholder {
	font-weight: 300;
}
.form-input:focus,
.form-multiselect:focus,
.form-select:focus,
.form-textarea:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
}
.form-input,
.form-multiselect,
.form-select,
.form-textarea {
	contain: paint;
}
.form-select {
	print-print-color-adjust: exact;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none'%3E%3Cpath stroke='%235C6B8A' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
	background-position: 50%;
	background-position: right 0.5rem center;
	background-repeat: no-repeat;
	background-size: 1.5em 1.5em;
}
.form-multiselect {
	print-print-color-adjust: unset;
	background-image: none;
	background-position: 0 0;
	background-repeat: unset;
	background-size: initial;
	padding-right: 0.75rem;
}
.form-checkbox,
.form-radio,
.form-switch {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	flex-shrink: 0;
	overflow: hidden;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.form-checkbox,
.form-checkbox:focus,
.form-radio,
.form-radio:focus,
.form-switch,
.form-switch:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
}
.form-checkbox,
.form-radio,
.form-switch {
	print-print-color-adjust: exact;
}
.form-switch {
	--thumb-border: 2px;
}
.form-switch:before {
	content: var(--tw-content);
	height: calc(100% - var(--thumb-border) * 2);
	left: var(--thumb-border);
	position: absolute;
	top: var(--thumb-border);
	width: calc(50% - var(--thumb-border) * 2);
}
.form-switch:checked:before {
	--tw-translate-x: calc(100% + var(--thumb-border) * 2);
	content: var(--tw-content);
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.form-switch:focus-visible {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
		var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0
		calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		var(--tw-shadow, 0 0 #0000);
}
.form-switch.is-outline:before {
	content: var(--tw-content);
	width: calc(50% - var(--thumb-border) * 2 - 1px);
}
.form-switch.is-outline:checked:before {
	--tw-translate-x: calc(100% + var(--thumb-border) * 2 + 2px);
	content: var(--tw-content);
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.form-radio {
	--tw-thumb: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3.5'/%3E%3C/svg%3E");
}
.form-radio:before {
	content: var(--tw-content);
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.form-checkbox {
	--tw-thumb: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
}
.form-checkbox:before {
	content: var(--tw-content);
	transform-origin: bottom;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.form-checkbox,
.form-radio {
	border-width: 1px;
	display: inline-block;
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.form-checkbox:before,
.form-radio:before {
	--tw-scale-x: 0;
	--tw-scale-y: 0;
	height: 100%;
	inset: 0;
	position: absolute;
	width: 100%;
}
.form-checkbox:before,
.form-checkbox:checked:before,
.form-radio:before,
.form-radio:checked:before {
	content: var(--tw-content);
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.form-checkbox:checked:before,
.form-radio:checked:before {
	--tw-scale-x: 1;
	--tw-scale-y: 1;
}
.form-checkbox:hover,
.form-radio:hover {
	--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
		0 4px 6px -4px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.form-checkbox.is-basic,
.form-radio.is-basic {
	background-origin: border-box;
}
.form-checkbox.is-basic:before,
.form-radio.is-basic:before {
	background-image: var(--tw-thumb);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	content: var(--tw-content);
}
.form-checkbox.is-outline:before,
.form-radio.is-outline:before {
	content: var(--tw-content);
	-webkit-mask-image: var(--tw-thumb);
	mask-image: var(--tw-thumb);
}
.form-range {
	--range-thumb-size: 1.125rem;
	--range-track-h: 0.375rem;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	display: block;
	outline: 2px solid transparent;
	outline-offset: 2px;
	width: 100%;
}
.form-range::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	background-color: currentColor;
	border-radius: 9999px;
	border-style: none;
	cursor: pointer;
	height: var(--range-thumb-size);
	position: relative;
	top: 50%;
	width: var(--range-thumb-size);
}
.form-range::-webkit-slider-thumb:active {
	opacity: 0.85;
}
.form-range::-webkit-slider-thumb {
	transform: translateY(-50%);
}
.form-range::-moz-range-thumb {
	-moz-appearance: none;
	appearance: none;
	background-color: currentColor;
	border-radius: 9999px;
	border-style: none;
	cursor: pointer;
	height: var(--range-thumb-size);
	position: relative;
	top: 50%;
	width: var(--range-thumb-size);
}
.form-range::-moz-range-thumb:active {
	opacity: 0.85;
}
.form-range::-moz-range-track {
	background-color: #e9eef5;
	border-radius: 9999px;
	height: var(--range-track-h);
	width: 100%;
}
:is(.dark .form-range)::-moz-range-track {
	background-color: #384766;
}
.form-range::-webkit-slider-runnable-track {
	background-color: #e9eef5;
	border-radius: 9999px;
	height: var(--range-track-h);
	width: 100%;
}
:is(.dark .form-range)::-webkit-slider-runnable-track {
	background-color: #384766;
}
:root {
	--ts-pr-clear-button: 0;
	--ts-pr-caret: 0;
	--ts-pr-min: 0.75rem;
}
.ts-wrapper.single .ts-control,
.ts-wrapper.single .ts-control input {
	cursor: pointer;
}
.ts-control {
	padding-right: max(
		var(--ts-pr-min),
		var(--ts-pr-clear-button) + var(--ts-pr-caret)
	) !important;
}
.ts-wrapper.plugin-drag_drop.multi > .ts-control > div.ui-sortable-placeholder {
	background: #f2f2f2 !important;
	background: rgba(0, 0, 0, 0.06) !important;
	border: 0 !important;
	box-shadow: inset 0 0 12px 4px #fff;
	visibility: visible !important;
}
.ts-wrapper.plugin-drag_drop .ui-sortable-placeholder:after {
	content: "!";
	visibility: hidden;
}
.ts-wrapper.plugin-drag_drop .ui-sortable-helper {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.plugin-checkbox_options .option input {
	margin-right: 0.5rem;
}
.plugin-clear_button {
	--ts-pr-clear-button: 1em;
}
.plugin-clear_button .clear-button {
	background: transparent !important;
	cursor: pointer;
	margin-right: 0 !important;
	opacity: 0;
	position: absolute;
	right: 2px;
	top: 50%;
	transform: translateY(-50%);
	transition: opacity 0.5s;
}
.plugin-clear_button.form-select .clear-button,
.plugin-clear_button.single .clear-button {
	right: max(var(--ts-pr-caret), 8px);
}
.plugin-clear_button.focus.has-items .clear-button,
.plugin-clear_button:not(.disabled):hover.has-items .clear-button {
	opacity: 1;
}
.ts-wrapper .dropdown-header {
	background: #f8f8f8;
	border-bottom: 1px solid #d0d0d0;
	border-radius: 3px 3px 0 0;
	padding: 10px 8px;
	position: relative;
}
.ts-wrapper .dropdown-header-close {
	color: #303030;
	font-size: 20px !important;
	line-height: 20px;
	margin-top: -12px;
	opacity: 0.4;
	position: absolute;
	right: 8px;
	top: 50%;
}
.ts-wrapper .dropdown-header-close:hover {
	color: #000;
}
.plugin-dropdown_input.focus.dropdown-active .ts-control {
	border: 1px solid #d0d0d0;
	box-shadow: none;
}
.plugin-dropdown_input .dropdown-input {
	background: transparent;
	border: solid #d0d0d0;
	border-width: 0 0 1px;
	box-shadow: none;
	display: block;
	padding: 8px;
	width: 100%;
}
.plugin-dropdown_input .items-placeholder {
	border: 0 !important;
	box-shadow: none !important;
	width: 100%;
}
.plugin-dropdown_input.dropdown-active .items-placeholder,
.plugin-dropdown_input.has-items .items-placeholder {
	display: none !important;
}
.ts-wrapper.plugin-input_autogrow.has-items .ts-control > input {
	min-width: 0;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input {
	flex: none;
	min-width: 4px;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus
	.ts-control
	> input::-ms-input-placeholder {
	color: transparent;
}
.ts-wrapper.plugin-input_autogrow.has-items.focus
	.ts-control
	> input::placeholder {
	color: transparent;
}
.ts-dropdown.plugin-optgroup_columns .ts-dropdown-content {
	display: flex;
}
.ts-dropdown.plugin-optgroup_columns .optgroup {
	border-right: 1px solid #f2f2f2;
	border-top: 0;
	flex-basis: 0;
	flex-grow: 1;
	min-width: 0;
}
.ts-dropdown.plugin-optgroup_columns .optgroup:last-child {
	border-right: 0;
}
.ts-dropdown.plugin-optgroup_columns .optgroup:before {
	display: none;
}
.ts-dropdown.plugin-optgroup_columns .optgroup-header {
	border-top: 0;
}
.ts-wrapper.plugin-remove_button .item {
	align-items: center;
	display: inline-flex;
	padding-right: 0 !important;
}
.ts-wrapper.plugin-remove_button .item .remove {
	border-radius: 0 2px 2px 0;
	box-sizing: border-box;
	color: inherit;
	display: inline-block;
	padding: 0 6px;
	text-decoration: none;
	vertical-align: middle;
}
.ts-wrapper.plugin-remove_button .item .remove:hover {
	background: rgba(0, 0, 0, 0.05);
}
.ts-wrapper.plugin-remove_button.disabled .item .remove:hover {
	background: none;
}
.ts-wrapper.plugin-remove_button .remove-single {
	font-size: 23px;
	position: absolute;
	right: 0;
	top: 0;
}
.ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
	border-left: 1px solid #d0d0d0;
	margin-left: 6px;
}
.ts-wrapper.plugin-remove_button:not(.rtl) .item.active .remove {
	border-left-color: #cacaca;
}
.ts-wrapper.plugin-remove_button:not(.rtl).disabled .item .remove {
	border-left-color: #fff;
}
.ts-wrapper.plugin-remove_button.rtl .item .remove {
	border-right: 1px solid #d0d0d0;
	margin-right: 6px;
}
.ts-wrapper.plugin-remove_button.rtl .item.active .remove {
	border-right-color: #cacaca;
}
.ts-wrapper.plugin-remove_button.rtl.disabled .item .remove {
	border-right-color: #fff;
}
.ts-wrapper {
	position: relative;
}
.ts-control,
.ts-control input,
.ts-dropdown {
	font-smoothing: inherit;
	color: #303030;
	font-family: inherit;
	font-size: 13px;
	line-height: 18px;
}
.ts-control,
.ts-wrapper.single.input-active .ts-control {
	background: #fff;
	cursor: text;
}
.ts-control {
	border: 1px solid #d0d0d0;
	border-radius: 3px;
	box-shadow: none;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	padding: 8px;
	position: relative;
	width: 100%;
	z-index: 1;
}
.ts-wrapper.multi.has-items .ts-control {
	padding: 6px 8px 3px;
}
.full .ts-control {
	background-color: #fff;
}
.disabled .ts-control,
.disabled .ts-control * {
	cursor: default !important;
}
.focus .ts-control {
	box-shadow: none;
}
.ts-control > * {
	display: inline-block;
	vertical-align: baseline;
}
.ts-wrapper.multi .ts-control > div {
	background: #f2f2f2;
	border: 0 solid #d0d0d0;
	color: #303030;
	cursor: pointer;
	margin: 0 3px 3px 0;
	padding: 2px 6px;
}
.ts-wrapper.multi .ts-control > div.active {
	background: #e8e8e8;
	border: 0 solid #cacaca;
	color: #303030;
}
.ts-wrapper.multi.disabled .ts-control > div,
.ts-wrapper.multi.disabled .ts-control > div.active {
	background: #fff;
	border: 0 solid #fff;
	color: #7d7d7d;
}
.ts-control > input {
	background: none !important;
	border: 0 !important;
	box-shadow: none !important;
	display: inline-block !important;
	flex: 1 1 auto;
	line-height: inherit !important;
	margin: 0 !important;
	max-height: none !important;
	max-width: 100% !important;
	min-height: 0 !important;
	min-width: 7rem;
	padding: 0 !important;
	text-indent: 0 !important;
	-webkit-user-select: auto !important;
	-moz-user-select: auto !important;
	-ms-user-select: auto !important;
	user-select: auto !important;
}
.ts-control > input::-ms-clear {
	display: none;
}
.ts-control > input:focus {
	outline: none !important;
}
.has-items .ts-control > input {
	margin: 0 4px !important;
}
.ts-control.rtl {
	text-align: right;
}
.ts-control.rtl.single .ts-control:after {
	left: 15px;
	right: auto;
}
.ts-control.rtl .ts-control > input {
	margin: 0 4px 0 -2px !important;
}
.disabled .ts-control {
	background-color: #fafafa;
	opacity: 0.5;
}
.input-hidden .ts-control > input {
	left: -10000px;
	opacity: 0;
	position: absolute;
}
.ts-dropdown {
	background: #fff;
	border: 1px solid #d0d0d0;
	border-radius: 0 0 3px 3px;
	border-top: 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	left: 0;
	margin: 0.25rem 0 0;
	position: absolute;
	top: 100%;
	width: 100%;
	z-index: 10;
}
.ts-dropdown [data-selectable] {
	cursor: pointer;
	overflow: hidden;
}
.ts-dropdown [data-selectable] .highlight {
	background: rgba(125, 168, 208, 0.2);
	border-radius: 1px;
}
.ts-dropdown .create,
.ts-dropdown .no-results,
.ts-dropdown .optgroup-header,
.ts-dropdown .option {
	padding: 5px 8px;
}
.ts-dropdown .option,
.ts-dropdown [data-disabled],
.ts-dropdown [data-disabled] [data-selectable].option {
	cursor: inherit;
	opacity: 0.5;
}
.ts-dropdown [data-selectable].option {
	cursor: pointer;
	opacity: 1;
}
.ts-dropdown .optgroup:first-child .optgroup-header {
	border-top: 0;
}
.ts-dropdown .optgroup-header {
	background: #fff;
	color: #303030;
	cursor: default;
}
.ts-dropdown .active {
	background-color: #f5fafd;
	color: #495c68;
}
.ts-dropdown .active.create {
	color: #495c68;
}
.ts-dropdown .create {
	color: rgba(48, 48, 48, 0.5);
}
.ts-dropdown .spinner {
	display: inline-block;
	height: 30px;
	margin: 5px 8px;
	width: 30px;
}
.ts-dropdown .spinner:after {
	animation: lds-dual-ring 1.2s linear infinite;
	border-color: #d0d0d0 transparent;
	border-radius: 50%;
	border-style: solid;
	border-width: 5px;
	content: " ";
	display: block;
	height: 24px;
	margin: 3px;
	width: 24px;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(1turn);
	}
}
.ts-dropdown-content {
	overflow-scrolling: touch;
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-behavior: smooth;
}
.ts-hidden-accessible {
	clip: rect(0 0 0 0) !important;
	border: 0 !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
}
.ts-control {
	border-color: #cbd5e1;
	border-radius: 0.5rem;
	font-family: inherit;
	font-weight: 500;
	outline: 2px solid transparent;
	outline-offset: 2px;
	padding: 0.5rem 0.75rem;
	transition-duration: 0.3s;
	transition-property: color, background-color, border-color,
		text-decoration-color, fill, stroke, opacity, box-shadow, transform,
		filter, -webkit-backdrop-filter;
	transition-property: color, background-color, border-color,
		text-decoration-color, fill, stroke, opacity, box-shadow, transform,
		filter, backdrop-filter;
	transition-property: color, background-color, border-color,
		text-decoration-color, fill, stroke, opacity, box-shadow, transform,
		filter, backdrop-filter, -webkit-backdrop-filter;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ts-control:hover {
	border-color: #94a3b8;
}
:is(.dark .ts-control) {
	background-color: #26334d;
	border-color: #465675;
	color: #c2c9d6;
}
:is(.dark .ts-control:hover) {
	border-color: #5c6b8a;
}
.input-active .ts-control {
	border-color: #4f46e5;
}
:is(.dark .input-active .ts-control) {
	border-color: #5f5af6;
}
.ts-control,
.ts-control input,
.ts-dropdown {
	color: #475569;
	font-size: 0.875rem;
	line-height: 1.25rem;
	line-height: 1.5;
}
.ts-control input::-moz-placeholder,
.ts-control::-moz-placeholder,
.ts-dropdown::-moz-placeholder {
	color: rgba(148, 163, 184, 0.7);
}
.ts-control input::placeholder,
.ts-control::placeholder,
.ts-dropdown::placeholder {
	color: rgba(148, 163, 184, 0.7);
}
:is(.dark .ts-control input),
:is(.dark .ts-control),
:is(.dark .ts-dropdown) {
	color: #c2c9d6;
}
.ts-wrapper.multi .ts-control > div {
	background-color: #e9eef5;
	border-radius: 0.25rem;
	color: #1e293b;
}
:is(.dark .ts-wrapper.multi .ts-control > div) {
	background-color: #384766;
	color: #e7e9ef;
}
.ts-dropdown {
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
	border-color: #e9eef5;
	border-radius: 0.375rem;
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	overflow: hidden;
}
:is(.dark .ts-dropdown) {
	background-color: #26334d;
	border-color: #313e59;
}
.ts-dropdown .create {
	color: #475569;
}
:is(.dark .ts-dropdown .create) {
	color: #c2c9d6;
}
.ts-dropdown .active,
.ts-dropdown .create:hover,
.ts-dropdown .option:hover {
	background-color: #4f46e5;
	color: #fff;
}
:is(.dark .ts-dropdown .active),
:is(.dark .ts-dropdown .create:hover),
:is(.dark .ts-dropdown .option:hover) {
	background-color: #5f5af6;
}
.ts-dropdown .active.create,
.ts-dropdown .create:hover.create,
.ts-dropdown .option:hover.create {
	color: #fff;
}
.ts-wrapper.plugin-remove_button .item .remove {
	border-color: #cbd5e1;
}
:is(.dark .ts-wrapper.plugin-remove_button .item .remove) {
	border-color: #5c6b8a;
}
.ts-control,
.ts-wrapper.single.input-active .ts-control {
	background-color: transparent;
}
.ts-wrapper.single .ts-control,
.ts-wrapper.single.input-active .ts-control {
	print-print-color-adjust: exact;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none'%3E%3Cpath stroke='%235C6B8A' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
	background-position: 50%;
	background-position: right 0.5rem center;
	background-repeat: no-repeat;
	background-size: 1.5em 1.5em;
	padding-right: 2.25rem;
}
.btn {
	align-items: center;
	border-radius: 0.5rem;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	letter-spacing: 0.025em;
	padding: 0.5rem 1.25rem;
	text-align: center;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.btn,
.btn:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
}
.btn:disabled {
	pointer-events: none;
}
.badge,
.tag {
	align-items: center;
	border-radius: 0.25rem;
	display: inline-flex;
	font-family: inherit;
	font-size: 0.75rem;
	font-weight: 500;
	justify-content: center;
	letter-spacing: 0.025em;
	line-height: 1rem;
	line-height: 1;
	padding: 0.375rem 0.5rem;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	vertical-align: baseline;
}
.tag {
	cursor: pointer;
}
[data-simplebar] {
	align-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	position: relative;
}
.simplebar-wrapper {
	height: inherit;
	max-height: inherit;
	max-width: inherit;
	overflow: hidden;
	width: inherit;
}
.simplebar-mask {
	direction: inherit;
	height: auto !important;
	overflow: hidden;
	width: auto !important;
	z-index: 0;
}
.simplebar-mask,
.simplebar-offset {
	bottom: 0;
	left: 0;
	margin: 0;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
}
.simplebar-offset {
	-webkit-overflow-scrolling: touch;
	box-sizing: inherit !important;
	direction: inherit !important;
	resize: none !important;
}
.simplebar-content-wrapper {
	-ms-overflow-style: none;
	box-sizing: border-box !important;
	direction: inherit;
	display: block;
	height: 100%;
	max-height: 100%;
	max-width: 100%;
	overflow: auto;
	position: relative;
	scrollbar-width: none;
	width: auto;
}
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
	display: none;
	height: 0;
	width: 0;
}
.simplebar-content:after,
.simplebar-content:before {
	content: " ";
	display: table;
}
.simplebar-placeholder {
	max-height: 100%;
	max-width: 100%;
	pointer-events: none;
	width: 100%;
}
.simplebar-height-auto-observer-wrapper {
	box-sizing: inherit !important;
	flex-basis: 0;
	flex-grow: inherit;
	flex-shrink: 0;
	float: left;
	height: 100%;
	margin: 0;
	max-height: 1px;
	max-width: 1px;
	overflow: hidden;
	padding: 0;
	pointer-events: none;
	position: relative;
	width: 100%;
	z-index: -1;
}
.simplebar-height-auto-observer {
	box-sizing: inherit;
	display: block;
	height: 1000%;
	left: 0;
	min-height: 1px;
	min-width: 1px;
	opacity: 0;
	top: 0;
	width: 1000%;
	z-index: -1;
}
.simplebar-height-auto-observer,
.simplebar-track {
	overflow: hidden;
	pointer-events: none;
	position: absolute;
}
.simplebar-track {
	bottom: 0;
	right: 0;
	z-index: 1;
}
[data-simplebar].simplebar-dragging,
[data-simplebar].simplebar-dragging .simplebar-content {
	-webkit-touch-callout: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
	pointer-events: all;
}
.simplebar-scrollbar {
	left: 0;
	min-height: 10px;
	position: absolute;
	right: 0;
}
.simplebar-scrollbar:before {
	background: #000;
	border-radius: 7px;
	content: "";
	opacity: 0;
	position: absolute;
	transition: opacity 0.2s linear 0.5s;
}
.simplebar-scrollbar.simplebar-visible:before {
	opacity: 0.5;
	transition-delay: 0s;
	transition-duration: 0s;
}
.simplebar-track.simplebar-vertical {
	top: 0;
	width: 11px;
}
.simplebar-scrollbar:before {
	bottom: 2px;
	left: 2px;
	right: 2px;
	top: 2px;
}
.simplebar-track.simplebar-horizontal {
	height: 11px;
	left: 0;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
	bottom: 0;
	left: 0;
	min-height: 0;
	min-width: 10px;
	right: auto;
	top: 0;
	width: auto;
}
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
	left: 0;
	right: auto;
}
.simplebar-dummy-scrollbar-size {
	-ms-overflow-style: scrollbar !important;
	direction: rtl;
	height: 500px;
	opacity: 0;
	overflow-x: scroll;
	overflow-y: hidden;
	position: fixed;
	visibility: hidden;
	width: 500px;
}
.simplebar-dummy-scrollbar-size > div {
	height: 200%;
	margin: 10px 0;
	width: 200%;
}
.simplebar-hide-scrollbar {
	-ms-overflow-style: none;
	left: 0;
	overflow-y: scroll;
	position: fixed;
	scrollbar-width: none;
	visibility: hidden;
}
.simplebar-scrollbar:before {
	background-color: rgba(203, 213, 225, 0.8);
}
:is(.dark .simplebar-scrollbar):before {
	background-color: #5c6b8a;
}
.simplebar-content-wrapper {
	outline: 2px solid transparent;
	outline-offset: 2px;
}
.progress {
	border-radius: 9999px;
	display: flex;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.progress .is-indeterminate {
	animation: progress-increase 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.progress .is-active:before {
	--tw-content: "";
	animation: progress-active 2s cubic-bezier(0.55, 0.2, 0.3, 0.67) infinite;
	background-color: #fff;
	border-radius: 0.25rem;
	content: var(--tw-content);
	inset: 0;
	position: absolute;
}
@keyframes progress-increase {
	0% {
		left: -5%;
		width: 5%;
	}
	to {
		left: 125%;
		width: 100%;
	}
}
@keyframes progress-active {
	0% {
		opacity: 0.4;
		transform: translateX(-100%);
	}
	to {
		opacity: 0;
		transform: translateX(0);
	}
}
.mask {
	-webkit-mask-position: center;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-size: contain;
	mask-size: contain;
}
.mask.is-squircle {
	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
}
.mask.is-reuleaux-triangle {
	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='207' height='207' fill='none'%3E%3Cpath fill='%23000' d='M138.648 181.408C47.268 232.569 1.327 206.607.824 103.52.324.432 46.014-25.148 137.896 26.777c91.882 51.925 92.133 103.469.753 154.631h-.001z'/%3E%3C/svg%3E");
	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='207' height='207' fill='none'%3E%3Cpath fill='%23000' d='M138.648 181.408C47.268 232.569 1.327 206.607.824 103.52.324.432 46.014-25.148 137.896 26.777c91.882 51.925 92.133 103.469.753 154.631h-.001z'/%3E%3C/svg%3E");
}
.mask.is-diamond {
	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='227' height='227' fill='none'%3E%3Cpath fill='%23000' d='M42.71 42.71c-56.568 56.57-56.568 84.853 0 141.422 56.57 56.569 84.853 56.569 141.422 0s56.569-84.853 0-141.421c-56.569-56.569-84.853-56.569-141.421 0z'/%3E%3C/svg%3E");
	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='227' height='227' fill='none'%3E%3Cpath fill='%23000' d='M42.71 42.71c-56.568 56.57-56.568 84.853 0 141.422 56.57 56.569 84.853 56.569 141.422 0s56.569-84.853 0-141.421c-56.569-56.569-84.853-56.569-141.421 0z'/%3E%3C/svg%3E");
}
.mask.is-hexagon {
	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='182' height='201' fill='none'%3E%3Cpath fill='%23000' d='M.3 65.486c0-9.196 6.687-20.063 14.211-25.078l61.86-35.946c8.36-5.016 20.899-5.016 29.258 0l61.86 35.946c8.36 5.015 14.211 15.882 14.211 25.078v71.055c0 9.196-6.687 20.063-14.211 25.079l-61.86 35.945c-8.36 4.18-20.899 4.18-29.258 0l-61.86-35.945C6.151 157.44.3 145.737.3 136.54V65.486z'/%3E%3C/svg%3E");
	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='182' height='201' fill='none'%3E%3Cpath fill='%23000' d='M.3 65.486c0-9.196 6.687-20.063 14.211-25.078l61.86-35.946c8.36-5.016 20.899-5.016 29.258 0l61.86 35.946c8.36 5.015 14.211 15.882 14.211 25.078v71.055c0 9.196-6.687 20.063-14.211 25.079l-61.86 35.945c-8.36 4.18-20.899 4.18-29.258 0l-61.86-35.945C6.151 157.44.3 145.737.3 136.54V65.486z'/%3E%3C/svg%3E");
}
.mask.is-hexagon-2 {
	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='182' fill='none'%3E%3Cpath fill='%23000' d='M64.786 181.4c-9.196 0-20.063-6.687-25.079-14.21L3.762 105.33c-5.016-8.36-5.016-20.9 0-29.259l35.945-61.86C44.723 5.851 55.59 0 64.786 0h71.055c9.196 0 20.063 6.688 25.079 14.211l35.945 61.86c4.18 8.36 4.18 20.899 0 29.258l-35.945 61.86c-4.18 8.36-15.883 14.211-25.079 14.211H64.786z'/%3E%3C/svg%3E");
	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='182' fill='none'%3E%3Cpath fill='%23000' d='M64.786 181.4c-9.196 0-20.063-6.687-25.079-14.21L3.762 105.33c-5.016-8.36-5.016-20.9 0-29.259l35.945-61.86C44.723 5.851 55.59 0 64.786 0h71.055c9.196 0 20.063 6.688 25.079 14.211l35.945 61.86c4.18 8.36 4.18 20.899 0 29.258l-35.945 61.86c-4.18 8.36-15.883 14.211-25.079 14.211H64.786z'/%3E%3C/svg%3E");
}
.mask.is-octagon {
	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='198' height='198' fill='none'%3E%3Cpath fill='%23000' d='M91.346 2.17a20 20 0 0 1 15.308 0l55.403 22.95a20 20 0 0 1 10.824 10.823l22.949 55.403a20.004 20.004 0 0 1 0 15.308l-22.949 55.403a20.006 20.006 0 0 1-10.824 10.824l-55.403 22.949a20.004 20.004 0 0 1-15.308 0l-55.403-22.949a20 20 0 0 1-10.824-10.824L2.17 106.654a20 20 0 0 1 0-15.308l22.95-55.403a20 20 0 0 1 10.823-10.824L91.346 2.17z'/%3E%3C/svg%3E");
	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='198' height='198' fill='none'%3E%3Cpath fill='%23000' d='M91.346 2.17a20 20 0 0 1 15.308 0l55.403 22.95a20 20 0 0 1 10.824 10.823l22.949 55.403a20.004 20.004 0 0 1 0 15.308l-22.949 55.403a20.006 20.006 0 0 1-10.824 10.824l-55.403 22.949a20.004 20.004 0 0 1-15.308 0l-55.403-22.949a20 20 0 0 1-10.824-10.824L2.17 106.654a20 20 0 0 1 0-15.308l22.95-55.403a20 20 0 0 1 10.823-10.824L91.346 2.17z'/%3E%3C/svg%3E");
}
.mask.is-star {
	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='180' fill='none'%3E%3Cpath fill='%23000' d='M82.39 2.111a14 14 0 0 1 14.568 0l10.401 6.337a14 14 0 0 0 7.613 2.04l12.176-.287a14.003 14.003 0 0 1 12.616 7.283l5.839 10.688a14.005 14.005 0 0 0 5.573 5.574l10.688 5.84a14.001 14.001 0 0 1 7.284 12.615l-.287 12.175a14.004 14.004 0 0 0 2.04 7.614l6.336 10.4a14 14 0 0 1 0 14.568l-6.336 10.401a14 14 0 0 0-2.04 7.613l.287 12.176a14.002 14.002 0 0 1-7.284 12.616l-10.688 5.839a14.005 14.005 0 0 0-5.573 5.573l-5.839 10.688a14.003 14.003 0 0 1-12.616 7.284l-12.176-.287a14 14 0 0 0-7.613 2.04l-10.401 6.336a13.998 13.998 0 0 1-14.567 0l-10.401-6.336a14.004 14.004 0 0 0-7.614-2.04l-12.175.287a13.994 13.994 0 0 1-12.616-7.284l-5.84-10.688a14.004 14.004 0 0 0-5.573-5.573l-10.688-5.839a14.001 14.001 0 0 1-7.283-12.616l.287-12.176a14 14 0 0 0-2.04-7.613L2.11 96.958a14 14 0 0 1 0-14.567L8.448 71.99a14 14 0 0 0 2.04-7.614L10.2 52.201a13.998 13.998 0 0 1 7.283-12.616l10.688-5.84a14 14 0 0 0 5.574-5.573l5.84-10.688A14 14 0 0 1 52.2 10.201l12.175.287a14 14 0 0 0 7.614-2.04l10.4-6.337h.001z'/%3E%3C/svg%3E");
	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='180' height='180' fill='none'%3E%3Cpath fill='%23000' d='M82.39 2.111a14 14 0 0 1 14.568 0l10.401 6.337a14 14 0 0 0 7.613 2.04l12.176-.287a14.003 14.003 0 0 1 12.616 7.283l5.839 10.688a14.005 14.005 0 0 0 5.573 5.574l10.688 5.84a14.001 14.001 0 0 1 7.284 12.615l-.287 12.175a14.004 14.004 0 0 0 2.04 7.614l6.336 10.4a14 14 0 0 1 0 14.568l-6.336 10.401a14 14 0 0 0-2.04 7.613l.287 12.176a14.002 14.002 0 0 1-7.284 12.616l-10.688 5.839a14.005 14.005 0 0 0-5.573 5.573l-5.839 10.688a14.003 14.003 0 0 1-12.616 7.284l-12.176-.287a14 14 0 0 0-7.613 2.04l-10.401 6.336a13.998 13.998 0 0 1-14.567 0l-10.401-6.336a14.004 14.004 0 0 0-7.614-2.04l-12.175.287a13.994 13.994 0 0 1-12.616-7.284l-5.84-10.688a14.004 14.004 0 0 0-5.573-5.573l-10.688-5.839a14.001 14.001 0 0 1-7.283-12.616l.287-12.176a14 14 0 0 0-2.04-7.613L2.11 96.958a14 14 0 0 1 0-14.567L8.448 71.99a14 14 0 0 0 2.04-7.614L10.2 52.201a13.998 13.998 0 0 1 7.283-12.616l10.688-5.84a14 14 0 0 0 5.574-5.573l5.84-10.688A14 14 0 0 1 52.2 10.201l12.175.287a14 14 0 0 0 7.614-2.04l10.4-6.337h.001z'/%3E%3C/svg%3E");
}
.mask.is-star-2 {
	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='187' height='187' fill='none'%3E%3Cpath fill='%23000' d='M81.518 3.291a24.647 24.647 0 0 1 23.93 0l18.134 10.07a24.65 24.65 0 0 0 5.188 2.148l19.943 5.703a24.645 24.645 0 0 1 16.921 16.921l5.702 19.942a24.665 24.665 0 0 0 2.15 5.189l10.068 18.134a24.64 24.64 0 0 1 0 23.93l-10.068 18.134a24.66 24.66 0 0 0-2.15 5.188l-5.702 19.943a24.64 24.64 0 0 1-6.27 10.651 24.638 24.638 0 0 1-10.651 6.269l-19.943 5.703a24.66 24.66 0 0 0-5.188 2.15l-18.134 10.068a24.644 24.644 0 0 1-23.93 0l-18.134-10.068a24.663 24.663 0 0 0-5.188-2.15l-19.943-5.703a24.641 24.641 0 0 1-16.921-16.92L15.63 128.65a24.635 24.635 0 0 0-2.149-5.188l-10.07-18.134a24.651 24.651 0 0 1 0-23.93l10.07-18.134a24.644 24.644 0 0 0 2.148-5.188l5.704-19.943a24.647 24.647 0 0 1 16.92-16.921l19.943-5.703a24.655 24.655 0 0 0 5.188-2.148L81.518 3.29z'/%3E%3C/svg%3E");
	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='187' height='187' fill='none'%3E%3Cpath fill='%23000' d='M81.518 3.291a24.647 24.647 0 0 1 23.93 0l18.134 10.07a24.65 24.65 0 0 0 5.188 2.148l19.943 5.703a24.645 24.645 0 0 1 16.921 16.921l5.702 19.942a24.665 24.665 0 0 0 2.15 5.189l10.068 18.134a24.64 24.64 0 0 1 0 23.93l-10.068 18.134a24.66 24.66 0 0 0-2.15 5.188l-5.702 19.943a24.64 24.64 0 0 1-6.27 10.651 24.638 24.638 0 0 1-10.651 6.269l-19.943 5.703a24.66 24.66 0 0 0-5.188 2.15l-18.134 10.068a24.644 24.644 0 0 1-23.93 0l-18.134-10.068a24.663 24.663 0 0 0-5.188-2.15l-19.943-5.703a24.641 24.641 0 0 1-16.921-16.92L15.63 128.65a24.635 24.635 0 0 0-2.149-5.188l-10.07-18.134a24.651 24.651 0 0 1 0-23.93l10.07-18.134a24.644 24.644 0 0 0 2.148-5.188l5.704-19.943a24.647 24.647 0 0 1 16.92-16.921l19.943-5.703a24.655 24.655 0 0 0 5.188-2.148L81.518 3.29z'/%3E%3C/svg%3E");
}
.mask.is-heart {
	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='185' fill='none'%3E%3Cpath fill='%23000' d='M100 184.606a15.382 15.382 0 0 1-8.653-2.678C53.565 156.28 37.205 138.695 28.182 127.7 8.952 104.264-.254 80.202.005 54.146.308 24.287 24.264 0 53.406 0c21.192 0 35.869 11.937 44.416 21.879a2.884 2.884 0 0 0 4.356 0C110.725 11.927 125.402 0 146.594 0c29.142 0 53.098 24.287 53.4 54.151.26 26.061-8.956 50.122-28.176 73.554-9.023 10.994-25.383 28.58-63.165 54.228a15.388 15.388 0 0 1-8.653 2.673z'/%3E%3C/svg%3E");
	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='185' fill='none'%3E%3Cpath fill='%23000' d='M100 184.606a15.382 15.382 0 0 1-8.653-2.678C53.565 156.28 37.205 138.695 28.182 127.7 8.952 104.264-.254 80.202.005 54.146.308 24.287 24.264 0 53.406 0c21.192 0 35.869 11.937 44.416 21.879a2.884 2.884 0 0 0 4.356 0C110.725 11.927 125.402 0 146.594 0c29.142 0 53.098 24.287 53.4 54.151.26 26.061-8.956 50.122-28.176 73.554-9.023 10.994-25.383 28.58-63.165 54.228a15.388 15.388 0 0 1-8.653 2.673z'/%3E%3C/svg%3E");
}

.tab-content.tab-shift-left {
	animation: cubic-bezier(var(--ease-in-out)) tab-shift-left 0.3s forwards;
}
.tab-content.tab-shift-up {
	animation: cubic-bezier(var(--ease-in-out)) tab-shift-up 0.3s forwards;
}
.tab-content:not(.is-active) {
	display: none !important;
}
@keyframes tab-shift-left {
	0% {
		opacity: 0;
		transform: translate3d(1rem, 0, 0);
	}
	to {
		opacity: 1;
		transform: translateZ(0);
	}
}
@keyframes tab-shift-up {
	0% {
		opacity: 0;
		transform: translate3d(0, 1rem, 0);
	}
	to {
		opacity: 1;
		transform: translateZ(0);
	}
}
.ac-panel {
	overflow: hidden;
}
.modal:not(.show) {
	display: none;
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.modal {
	animation: cubic-bezier(var(--ease-out)) fade-in 0.3s;
}
@keyframes fade-out {
	0% {
		opacity: 1;
		visibility: visible;
	}
	to {
		opacity: 0;
		visibility: hidden;
	}
}
.modal.animate\:leave {
	animation: cubic-bezier(var(--ease-in)) fade-out 0.3s;
}
.modal.shift-up {
	--modal-from: translate3d(0, 1rem, 0);
	--modal-to: translate3d(0, 0, 0);
}
.modal.modal-sheet {
	--modal-from: translateY(100%);
	--modal-to: translateY(0);
}
.modal.shift-down {
	--modal-to: translate3d(0, 0, 0);
	--modal-from: translate3d(0, -1rem, 0);
}
.modal.modal-scale {
	--modal-from: scale(0.95);
	--modal-to: scale(1);
}
.modal.modal-scale .modal-content,
.modal.modal-sheet .modal-content,
.modal.shift-down .modal-content,
.modal.shift-up .modal-content {
	animation: cubic-bezier(var(--ease-out)) modal-open 0.3s;
}
.modal.modal-scale.animate\:leave .modal-content,
.modal.modal-sheet.animate\:leave .modal-content,
.modal.shift-down.animate\:leave .modal-content,
.modal.shift-up.animate\:leave .modal-content {
	animation: cubic-bezier(var(--ease-in)) modal-close 0.3s;
}
@keyframes modal-open {
	0% {
		transform: var(--modal-from);
	}
	to {
		transform: var(--modal-to);
	}
}
@keyframes modal-close {
	0% {
		transform: var(--modal-to);
	}
	to {
		transform: var(--modal-from);
	}
}
.drawer {
	--drawer-from: translate(-100%);
	--drawer-to: translate(0%);
}
.drawer.drawer-right {
	--drawer-to: translate(0%);
	--drawer-from: translate(100%);
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.drawer .drawer-overlay {
	animation: cubic-bezier(var(--ease-out)) fade-in 0.2s;
}
@keyframes fade-out {
	0% {
		opacity: 1;
		visibility: visible;
	}
	to {
		opacity: 0;
		visibility: hidden;
	}
}
.drawer .drawer-overlay.animate\:leave {
	animation: cubic-bezier(var(--ease-in)) fade-out 0.2s;
}
.drawer .drawer-content {
	animation: cubic-bezier(var(--ease-out)) drawer-open 0.2s;
}
.drawer .drawer-content.animate\:leave {
	animation: cubic-bezier(var(--ease-in)) drawer-close 0.2s;
}
@keyframes drawer-open {
	0% {
		transform: var(--drawer-from);
	}
	to {
		transform: var(--drawer-to);
	}
}
@keyframes drawer-close {
	0% {
		transform: var(--drawer-to);
	}
	to {
		transform: var(--drawer-from);
	}
}

.pointer-events-none {
	pointer-events: none;
}
.pointer-events-auto {
	pointer-events: auto;
}
.collapse {
	visibility: collapse;
}
.fixed {
	position: fixed;
}
.absolute {
	position: absolute;
}
.relative {
	position: relative;
}
.sticky {
	position: sticky;
}
.inset-0 {
	inset: 0;
}
.-right-px {
	right: -1px;
}
.-top-px {
	top: -1px;
}
.bottom-0 {
	bottom: 0;
}
.bottom-3 {
	bottom: 0.75rem;
}
.bottom-4 {
	bottom: 1rem;
}
.left-0 {
	left: 0;
}
.right-0 {
	right: 0;
}
.right-3 {
	right: 0.75rem;
}
.top-0 {
	top: 0;
}
.top-1\/2 {
	top: 50%;
}
.top-24 {
	top: 6rem;
}
.z-10 {
	z-index: 10;
}
.z-2 {
	z-index: 2;
}
.z-50 {
	z-index: 50;
}
.z-\[100\] {
	z-index: 100;
}
.z-\[101\] {
	z-index: 101;
}
.z-\[150\] {
	z-index: 150;
}
.z-\[151\] {
	z-index: 151;
}
.order-first {
	order: -9999;
}
.col-span-1 {
	grid-column: span 1 / span 1;
}
.col-span-2 {
	grid-column: span 2 / span 2;
}
.col-span-3 {
	grid-column: span 3 / span 3;
}
.col-span-4 {
	grid-column: span 4 / span 4;
}
.col-span-5 {
	grid-column: span 5 / span 5;
}
.col-span-6 {
	grid-column: span 6 / span 6;
}
.col-span-7 {
	grid-column: span 7 / span 7;
}
.col-span-8 {
	grid-column: span 8 / span 8;
}
.col-span-9 {
	grid-column: span 9 / span 9;
}
.col-span-10 {
	grid-column: span 10 / span 10;
}
.col-span-11 {
	grid-column: span 11 / span 11;
}
.col-span-12 {
	grid-column: span 12 / span 12;
}
.row-span-1 {
	grid-row: span 1 / span 1;
}
.row-span-2 {
	grid-row: span 2 / span 2;
}
.-m-0 {
	margin: 0;
}
.-m-0\.5 {
	margin: -0.125rem;
}
.-m-1 {
	margin: -0.25rem;
}
.-m-3 {
	margin: -0.75rem;
}
.-m-px {
	margin: -1px;
}
.m-0 {
	margin: 0;
}
.m-0\.5 {
	margin: 0.125rem;
}
.m-1 {
	margin: 0.25rem;
}
.m-1\.5 {
	margin: 0.375rem;
}
.m-2 {
	margin: 0.5rem;
}
.m-auto {
	margin: auto;
}
.-mx-2 {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
}
.mx-0 {
	margin-left: 0;
	margin-right: 0;
}
.mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.mx-3 {
	margin-left: 0.75rem;
	margin-right: 0.75rem;
}
.mx-4 {
	margin-left: 1rem;
	margin-right: 1rem;
}
.mx-5 {
	margin-left: 1.25rem;
	margin-right: 1.25rem;
}
.mx-auto {
	margin-left: auto;
	margin-right: auto;
}
.my-0 {
	margin-bottom: 0;
	margin-top: 0;
}
.my-0\.5 {
	margin-bottom: 0.125rem;
	margin-top: 0.125rem;
}
.my-1 {
	margin-bottom: 0.25rem;
	margin-top: 0.25rem;
}
.my-2 {
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
}
.my-3 {
	margin-bottom: 0.75rem;
	margin-top: 0.75rem;
}
.my-3\.5 {
	margin-bottom: 0.875rem;
	margin-top: 0.875rem;
}
.my-4 {
	margin-bottom: 1rem;
	margin-top: 1rem;
}
.my-5 {
	margin-bottom: 1.25rem;
	margin-top: 1.25rem;
}
.my-7 {
	margin-bottom: 1.75rem;
	margin-top: 1.75rem;
}
.-mb-0 {
	margin-bottom: 0;
}
.-mb-0\.5 {
	margin-bottom: -0.125rem;
}
.-ml-1 {
	margin-left: -0.25rem;
}
.-ml-1\.5 {
	margin-left: -0.375rem;
}
.-mr-1 {
	margin-right: -0.25rem;
}
.-mr-1\.5 {
	margin-right: -0.375rem;
}
.-mr-2 {
	margin-right: -0.5rem;
}
.-mt-1 {
	margin-top: -0.25rem;
}
.-mt-12 {
	margin-top: -3rem;
}
.-mt-16 {
	margin-top: -4rem;
}
.-mt-20 {
	margin-top: -5rem;
}
.-mt-3 {
	margin-top: -0.75rem;
}
.-mt-5 {
	margin-top: -1.25rem;
}
.-mt-8 {
	margin-top: -2rem;
}
.mb-1 {
	margin-bottom: 0.25rem;
}
.ml-0 {
	margin-left: 0;
}
.ml-0\.5 {
	margin-left: 0.125rem;
}
.ml-1 {
	margin-left: 0.25rem;
}
.ml-4 {
	margin-left: 1rem;
}
.ml-auto {
	margin-left: auto;
}
.mr-1 {
	margin-right: 0.25rem;
}
.mr-1\.5 {
	margin-right: 0.375rem;
}
.mr-2 {
	margin-right: 0.5rem;
}
.mr-3 {
	margin-right: 0.75rem;
}
.mr-4 {
	margin-right: 1rem;
}
.mt-0 {
	margin-top: 0;
}
.mt-0\.5 {
	margin-top: 0.125rem;
}
.mt-1 {
	margin-top: 0.25rem;
}
.mt-1\.5 {
	margin-top: 0.375rem;
}
.mt-10 {
	margin-top: 2.5rem;
}
.mt-12 {
	margin-top: 3rem;
}
.mt-16 {
	margin-top: 4rem;
}
.mt-2 {
	margin-top: 0.5rem;
}
.mt-2\.5 {
	margin-top: 0.625rem;
}
.mt-20 {
	margin-top: 5rem;
}
.mt-3 {
	margin-top: 0.75rem;
}
.mt-3\.5 {
	margin-top: 0.875rem;
}
.mt-4 {
	margin-top: 1rem;
}
.mt-5 {
	margin-top: 1.25rem;
}
.mt-6 {
	margin-top: 1.5rem;
}
.mt-8 {
	margin-top: 2rem;
}
.mt-px {
	margin-top: 1px;
}
.line-clamp-1 {
	-webkit-line-clamp: 1;
}
.line-clamp-1,
.line-clamp-2 {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
}
.line-clamp-2 {
	-webkit-line-clamp: 2;
}
.line-clamp-3 {
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	display: -webkit-box;
	overflow: hidden;
}
.block {
	display: block;
}
.inline-block {
	display: inline-block;
}
.inline {
	display: inline;
}
.flex {
	display: flex;
}
.inline-flex {
	display: inline-flex;
}
.table {
	display: table;
}
.grid {
	display: grid;
}
.hidden {
	display: none;
}
.aspect-square {
	aspect-ratio: 1/1;
}
.h-1 {
	height: 0.25rem;
}
.h-1\.5 {
	height: 0.375rem;
}
.h-10 {
	height: 2.5rem;
}
.h-11 {
	height: 2.75rem;
}
.h-12 {
	height: 3rem;
}
.h-14 {
	height: 3.5rem;
}
.h-16 {
	height: 4rem;
}
.h-18 {
	height: 4.5rem;
}
.h-2 {
	height: 0.5rem;
}
.h-2\.5 {
	height: 0.625rem;
}
.h-20 {
	height: 5rem;
}
.h-24 {
	height: 6rem;
}
.h-28 {
	height: 7rem;
}
.h-3 {
	height: 0.75rem;
}
.h-3\.5 {
	height: 0.875rem;
}
.h-36 {
	height: 9rem;
}
.h-4 {
	height: 1rem;
}
.h-4\.5 {
	height: 1.125rem;
}
.h-40 {
	height: 10rem;
}
.h-44 {
	height: 11rem;
}
.h-48 {
	height: 12rem;
}
.h-5 {
	height: 1.25rem;
}
.h-5\.5 {
	height: 1.375rem;
}
.h-56 {
	height: 14rem;
}
.h-6 {
	height: 1.5rem;
}
.h-64 {
	height: 16rem;
}
.h-7 {
	height: 1.75rem;
}
.h-72 {
	height: 18rem;
}
.h-8 {
	height: 2rem;
}
.h-80 {
	height: 20rem;
}
.h-9 {
	height: 2.25rem;
}
.h-\[60px\] {
	height: 60px;
}
.h-\[61px\] {
	height: 61px;
}
.h-\[calc\(100\%-2\.5rem\)\] {
	height: calc(100% - 2.5rem);
}
.h-\[calc\(100\%-4\.5rem\)\] {
	height: calc(100% - 4.5rem);
}
.h-\[calc\(100vh-8\.5rem\)\] {
	height: calc(100vh - 8.5rem);
}
.h-full {
	height: 100%;
}
.h-px {
	height: 1px;
}
.max-h-96 {
	max-height: 24rem;
}
.max-h-\[calc\(100vh-120px\)\] {
	max-height: calc(100vh - 120px);
}
.max-h-\[calc\(100vh-6rem\)\] {
	max-height: calc(100vh - 6rem);
}
.max-h-full {
	max-height: 100%;
}
.\!w-10\/12 {
	width: 83.333333% !important;
}
.w-0 {
	width: 0;
}
.w-1 {
	width: 0.25rem;
}
.w-1\.5 {
	width: 0.375rem;
}
.w-1\/12 {
	width: 8.333333%;
}
.w-1\/2 {
	width: 50%;
}
.w-10 {
	width: 2.5rem;
}
.w-10\/12 {
	width: 83.333333%;
}
.w-11 {
	width: 2.75rem;
}
.w-12 {
	width: 3rem;
}
.w-14 {
	width: 3.5rem;
}
.w-16 {
	width: 4rem;
}
.w-18 {
	width: 4.5rem;
}
.w-2 {
	width: 0.5rem;
}
.w-2\.5 {
	width: 0.625rem;
}
.w-2\/12 {
	width: 16.666667%;
}
.w-20 {
	width: 5rem;
}
.w-24 {
	width: 6rem;
}
.w-28 {
	width: 7rem;
}
.w-3 {
	width: 0.75rem;
}
.w-3\.5 {
	width: 0.875rem;
}
.w-3\/12 {
	width: 25%;
}
.w-32 {
	width: 8rem;
}
.w-36 {
	width: 9rem;
}
.w-4 {
	width: 1rem;
}
.w-4\.5 {
	width: 1.125rem;
}
.w-4\/12 {
	width: 33.333333%;
}
.w-40 {
	width: 10rem;
}
.w-48 {
	width: 12rem;
}
.w-5 {
	width: 1.25rem;
}
.w-5\.5 {
	width: 1.375rem;
}
.w-5\/12 {
	width: 41.666667%;
}
.w-56 {
	width: 14rem;
}
.w-6 {
	width: 1.5rem;
}
.w-6\/12 {
	width: 50%;
}
.w-60 {
	width: 15rem;
}
.w-64 {
	width: 16rem;
}
.w-7 {
	width: 1.75rem;
}
.w-7\/12 {
	width: 58.333333%;
}
.w-72 {
	width: 18rem;
}
.w-8 {
	width: 2rem;
}
.w-8\/12 {
	width: 66.666667%;
}
.w-80 {
	width: 20rem;
}
.w-9 {
	width: 2.25rem;
}
.w-9\/12 {
	width: 75%;
}
.w-\[calc\(100vw-2rem\)\] {
	width: calc(100vw - 2rem);
}
.w-full {
	width: 100%;
}
.w-max {
	width: max-content;
}
.w-px {
	width: 1px;
}
.min-w-\[1\.125rem\] {
	min-width: 1.125rem;
}
.min-w-\[1\.25rem\] {
	min-width: 1.25rem;
}
.min-w-\[1\.75rem\] {
	min-width: 1.75rem;
}
.min-w-\[1rem\] {
	min-width: 1rem;
}
.min-w-\[2\.5rem\] {
	min-width: 2.5rem;
}
.min-w-\[20rem\] {
	min-width: 20rem;
}
.min-w-\[2rem\] {
	min-width: 2rem;
}
.min-w-\[7rem\] {
	min-width: 7rem;
}
.min-w-full {
	min-width: 100%;
}
.max-w-2xl {
	max-width: 42rem;
}
.max-w-4xl {
	max-width: 56rem;
}
.max-w-\[16rem\] {
	max-width: 16rem;
}
.max-w-\[26rem\] {
	max-width: 26rem;
}
.max-w-lg {
	max-width: 32rem;
}
.max-w-md {
	max-width: 28rem;
}
.max-w-screen-lg {
	max-width: 1024px;
}
.max-w-sm {
	max-width: 24rem;
}
.max-w-xl {
	max-width: 36rem;
}
.max-w-xs {
	max-width: 20rem;
}
.flex-1 {
	flex: 1 1 0%;
}
.shrink-0 {
	flex-shrink: 0;
}
.flex-grow,
.grow {
	flex-grow: 1;
}
.origin-bottom {
	transform-origin: bottom;
}
.origin-center {
	transform-origin: center;
}
.origin-top {
	transform-origin: top;
}
.translate-x-1\/4 {
	--tw-translate-x: 25%;
}
.translate-x-1\/4,
.translate-y-1\/4 {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-1\/4 {
	--tw-translate-y: 25%;
}
.rotate-45 {
	--tw-rotate: 45deg;
}
.rotate-45,
.rotate-\[135deg\] {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-\[135deg\] {
	--tw-rotate: 135deg;
}
.transform-gpu {
	transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.animate-\[var\(--ease-in-out\)_fade-out_500ms_forwards\] {
	animation: var(--ease-in-out) fade-out 0.5s forwards;
}
@keyframes ping {
	75%,
	to {
		opacity: 0;
		transform: scale(2);
	}
}
.animate-ping {
	animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes pulse {
	50% {
		opacity: 0.5;
	}
}
.animate-pulse {
	animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes spin {
	to {
		transform: rotate(1turn);
	}
}
.animate-spin {
	animation: spin 1s linear infinite;
}
.cursor-pointer {
	cursor: pointer;
}
.resize-none {
	resize: none;
}
.resize {
	resize: both;
}
.list-inside {
	list-style-position: inside;
}
.list-disc {
	list-style-type: disc;
}
.grid-cols-1 {
	grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-12 {
	grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid-cols-2 {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
	grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
	grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-7 {
	grid-template-columns: repeat(7, minmax(0, 1fr));
}
.flex-row {
	flex-direction: row;
}
.flex-col {
	flex-direction: column;
}
.flex-col-reverse {
	flex-direction: column-reverse;
}
.flex-wrap {
	flex-wrap: wrap;
}
.place-content-center {
	place-content: center;
}
.place-items-start {
	place-items: start;
}
.place-items-center {
	place-items: center;
}
.items-start {
	align-items: flex-start;
}
.items-end {
	align-items: flex-end;
}
.items-center {
	align-items: center;
}
.items-baseline {
	align-items: baseline;
}
.justify-end {
	justify-content: flex-end;
}
.justify-center {
	justify-content: center;
}
.justify-between {
	justify-content: space-between;
}
.gap-1 {
	gap: 0.25rem;
}
.gap-10 {
	gap: 2.5rem;
}
.gap-12 {
	gap: 3rem;
}
.gap-2 {
	gap: 0.5rem;
}
.gap-3 {
	gap: 0.75rem;
}
.gap-4 {
	gap: 1rem;
}
.gap-6 {
	gap: 1.5rem;
}
.gap-8 {
	gap: 2rem;
}
.gap-x-4 {
	column-gap: 1rem;
}
.gap-y-4 {
	row-gap: 1rem;
}
.gap-y-8 {
	row-gap: 2rem;
}
.-space-x-1 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(-0.25rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
}
.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(-0.375rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
}
.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(-0.5rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
}
.-space-x-3 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(-0.75rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
}
.-space-x-px > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(-1px * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(-1px * var(--tw-space-x-reverse));
}
.space-x-0 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(0px * var(--tw-space-x-reverse));
}
.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(0.125rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(0.125rem * var(--tw-space-x-reverse));
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(0.25rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(0.25rem * var(--tw-space-x-reverse));
}
.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(0.375rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(0.375rem * var(--tw-space-x-reverse));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(0.5rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(0.5rem * var(--tw-space-x-reverse));
}
.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(0.625rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(0.625rem * var(--tw-space-x-reverse));
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(0.75rem * var(--tw-space-x-reverse));
}
.space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(0.875rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(0.875rem * var(--tw-space-x-reverse));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(1rem * var(--tw-space-x-reverse));
}
.space-x-5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(1.25rem * var(--tw-space-x-reverse));
}
.space-x-6 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(1.5rem * var(--tw-space-x-reverse));
}
.space-x-7 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
	margin-right: calc(1.75rem * var(--tw-space-x-reverse));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
	margin-top: calc(0.25rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
	margin-top: calc(0.375rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
	margin-top: calc(0.5rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
	margin-top: calc(0.625rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-20 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(5rem * var(--tw-space-y-reverse));
	margin-top: calc(5rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
	margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
	margin-top: calc(0.875rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(1rem * var(--tw-space-y-reverse));
	margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-5 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
	margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
	margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
}
.space-y-9 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
	margin-top: calc(2.25rem * (1 - var(--tw-space-y-reverse)));
}
.divide-x > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-x-reverse: 0;
	border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
	border-right-width: calc(1px * var(--tw-divide-x-reverse));
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-y-reverse: 0;
	border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
	border-top-width: calc(1px * (1 - var(--tw-divide-y-reverse)));
}
.divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
	border-color: #818cf8;
}
.divide-sky-400 > :not([hidden]) ~ :not([hidden]) {
	border-color: #38bdf8;
}
.divide-slate-150 > :not([hidden]) ~ :not([hidden]) {
	border-color: #e9eef5;
}
.divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
	border-color: #facc15;
}
.self-stretch {
	align-self: stretch;
}
.overflow-auto {
	overflow: auto;
}
.overflow-hidden {
	overflow: hidden;
}
.overflow-x-auto {
	overflow-x: auto;
}
.overflow-y-auto {
	overflow-y: auto;
}
.overflow-x-hidden {
	overflow-x: hidden;
}
.overflow-y-hidden {
	overflow-y: hidden;
}
.overscroll-contain {
	overscroll-behavior: contain;
}
.text-ellipsis {
	text-overflow: ellipsis;
}
.whitespace-nowrap {
	white-space: nowrap;
}
.break-words {
	overflow-wrap: break-word;
}
.rounded {
	border-radius: 0.25rem;
}
.rounded-2xl {
	border-radius: 1rem;
}
.rounded-full {
	border-radius: 9999px;
}
.rounded-lg {
	border-radius: 0.5rem;
}
.rounded-md {
	border-radius: 0.375rem;
}
.rounded-none {
	border-radius: 0;
}
.rounded-xl {
	border-radius: 0.75rem;
}
.rounded-b-lg {
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
}
.rounded-l-full {
	border-bottom-left-radius: 9999px;
	border-top-left-radius: 9999px;
}
.rounded-l-lg {
	border-bottom-left-radius: 0.5rem;
	border-top-left-radius: 0.5rem;
}
.rounded-l-none {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}
.rounded-r-full {
	border-bottom-right-radius: 9999px;
	border-top-right-radius: 9999px;
}
.rounded-r-lg {
	border-bottom-right-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
}
.rounded-r-none {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}
.rounded-t-2xl {
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
}
.rounded-t-lg {
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
}
.rounded-bl-lg {
	border-bottom-left-radius: 0.5rem;
}
.rounded-br-lg {
	border-bottom-right-radius: 0.5rem;
}
.rounded-tl-lg {
	border-top-left-radius: 0.5rem;
}
.rounded-tl-none {
	border-top-left-radius: 0;
}
.rounded-tr-lg {
	border-top-right-radius: 0.5rem;
}
.rounded-tr-none {
	border-top-right-radius: 0;
}
.border {
	border-width: 1px;
}
.border-2 {
	border-width: 2px;
}
.border-4 {
	border-width: 4px;
}
.border-\[3px\] {
	border-width: 3px;
}
.border-y {
	border-top-width: 1px;
}
.border-b,
.border-y {
	border-bottom-width: 1px;
}
.border-b-0 {
	border-bottom-width: 0;
}
.border-b-2 {
	border-bottom-width: 2px;
}
.border-l {
	border-left-width: 1px;
}
.border-l-0 {
	border-left-width: 0;
}
.border-l-4 {
	border-left-width: 4px;
}
.border-r {
	border-right-width: 1px;
}
.border-r-0 {
	border-right-width: 0;
}
.border-t {
	border-top-width: 1px;
}
.border-t-0 {
	border-top-width: 0;
}
.border-dashed {
	border-style: dashed;
}
.border-dotted {
	border-style: dotted;
}
.border-current {
	border-color: currentColor;
}
.border-error {
	border-color: #ff5724;
}
.border-error\/30 {
	border-color: rgba(255, 87, 36, 0.3);
}
.border-info {
	border-color: #0ea5e9;
}
.border-info\/30 {
	border-color: rgba(14, 165, 233, 0.3);
}
.border-navy-600 {
	border-color: #313e59;
}
.border-primary {
	border-color: #4f46e5;
}
.border-primary\/30 {
	border-color: rgba(79, 70, 229, 0.3);
}
.border-secondary {
	border-color: #f000b9;
}
.border-secondary\/30 {
	border-color: rgba(240, 0, 185, 0.3);
}
.border-slate-150 {
	border-color: #e9eef5;
}
.border-slate-200 {
	border-color: #e2e8f0;
}
.border-slate-300 {
	border-color: #cbd5e1;
}
.border-slate-400\/70 {
	border-color: rgba(148, 163, 184, 0.7);
}
.border-slate-50 {
	border-color: #f8fafc;
}
.border-slate-500 {
	border-color: #64748b;
}
.border-success {
	border-color: #10b981;
}
.border-success\/30 {
	border-color: rgba(16, 185, 129, 0.3);
}
.border-transparent {
	border-color: transparent;
}
.border-warning {
	border-color: #ff9800;
}
.border-warning\/30 {
	border-color: rgba(255, 152, 0, 0.3);
}
.border-white {
	border-color: #fff;
}
.border-white\/10 {
	border-color: hsla(0, 0%, 100%, 0.1);
}
.border-b-slate-200 {
	border-bottom-color: #e2e8f0;
}
.border-l-info {
	border-left-color: #0ea5e9;
}
.border-l-secondary {
	border-left-color: #f000b9;
}
.border-l-warning {
	border-left-color: #ff9800;
}
.border-r-error {
	border-right-color: #ff5724;
}
.border-r-info {
	border-right-color: #0ea5e9;
}
.border-r-primary {
	border-right-color: #4f46e5;
}
.border-r-secondary {
	border-right-color: #f000b9;
}
.border-r-slate-400 {
	border-right-color: #94a3b8;
}
.border-r-slate-500 {
	border-right-color: #64748b;
}
.border-r-success {
	border-right-color: #10b981;
}
.border-r-transparent {
	border-right-color: transparent;
}
.border-r-warning {
	border-right-color: #ff9800;
}
.bg-accent {
	background-color: #5f5af6;
}
.bg-black\/20 {
	background-color: rgba(0, 0, 0, 0.2);
}
.bg-black\/30 {
	background-color: rgba(0, 0, 0, 0.3);
}
.bg-current {
	background-color: currentColor;
}
.bg-error {
	background-color: #ff5724;
}
.bg-error\/10 {
	background-color: rgba(255, 87, 36, 0.1);
}
.bg-error\/15 {
	background-color: rgba(255, 87, 36, 0.15);
}
.bg-info {
	background-color: #0ea5e9;
}
.bg-info\/10 {
	background-color: rgba(14, 165, 233, 0.1);
}
.bg-info\/15 {
	background-color: rgba(14, 165, 233, 0.15);
}
.bg-navy-500 {
	background-color: #384766;
}
.bg-navy-600 {
	background-color: #313e59;
}
.bg-navy-700 {
	background-color: #26334d;
}
.bg-navy-900 {
	background-color: #192132;
}
.bg-primary {
	background-color: #4f46e5;
}
.bg-primary-focus {
	background-color: #4338ca;
}
.bg-primary\/10 {
	background-color: rgba(79, 70, 229, 0.1);
}
.bg-primary\/15 {
	background-color: rgba(79, 70, 229, 0.15);
}
.bg-secondary {
	background-color: #f000b9;
}
.bg-secondary-focus {
	background-color: #bd0090;
}
.bg-secondary\/10 {
	background-color: rgba(240, 0, 185, 0.1);
}
.bg-secondary\/15 {
	background-color: rgba(240, 0, 185, 0.15);
}
.bg-slate-100 {
	background-color: #f1f5f9;
}
.bg-slate-150 {
	background-color: #e9eef5;
}
.bg-slate-200 {
	background-color: #e2e8f0;
}
.bg-slate-300 {
	background-color: #cbd5e1;
}
.bg-slate-50 {
	background-color: #f8fafc;
}
.bg-slate-500 {
	background-color: #64748b;
}
.bg-slate-900\/60 {
	background-color: rgba(15, 23, 42, 0.6);
}
.bg-success {
	background-color: #10b981;
}
.bg-success\/10 {
	background-color: rgba(16, 185, 129, 0.1);
}
.bg-success\/15 {
	background-color: rgba(16, 185, 129, 0.15);
}
.bg-transparent {
	background-color: transparent;
}
.bg-warning {
	background-color: #ff9800;
}
.bg-warning\/10 {
	background-color: rgba(255, 152, 0, 0.1);
}
.bg-warning\/15 {
	background-color: rgba(255, 152, 0, 0.15);
}
.bg-white {
	background-color: #fff;
}
.bg-white\/20 {
	background-color: hsla(0, 0%, 100%, 0.2);
}
.bg-white\/30 {
	background-color: hsla(0, 0%, 100%, 0.3);
}
.bg-white\/90 {
	background-color: hsla(0, 0%, 100%, 0.9);
}
.bg-gradient-to-b {
	background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.bg-gradient-to-br {
	background-image: linear-gradient(
		to bottom right,
		var(--tw-gradient-stops)
	);
}
.bg-gradient-to-l {
	background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
.bg-gradient-to-r {
	background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.bg-gradient-to-t {
	background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.from-\[\#19213299\] {
	--tw-gradient-from: #19213299 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(25, 33, 50, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[\#ffffff55\] {
	--tw-gradient-from: #ffffff55 var(--tw-gradient-from-position);
	--tw-gradient-to: hsla(0, 0%, 100%, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-amber-400 {
	--tw-gradient-from: #fbbf24 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(251, 191, 36, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-blue-500 {
	--tw-gradient-from: #3b82f6 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(59, 130, 246, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-fuchsia-600 {
	--tw-gradient-from: #c026d3 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(192, 38, 211, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-green-400 {
	--tw-gradient-from: #4ade80 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(74, 222, 128, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-indigo-400 {
	--tw-gradient-from: #818cf8 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(129, 140, 248, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-info {
	--tw-gradient-from: #0ea5e9 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(14, 165, 233, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-pink-300 {
	--tw-gradient-from: #f9a8d4 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(249, 168, 212, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-pink-500 {
	--tw-gradient-from: #ec4899 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(236, 72, 153, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-purple-500 {
	--tw-gradient-from: #a855f7 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(168, 85, 247, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-secondary-light {
	--tw-gradient-from: #ff57d8 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(255, 87, 216, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-sky-400 {
	--tw-gradient-from: #38bdf8 var(--tw-gradient-from-position);
	--tw-gradient-to: rgba(56, 189, 248, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.via-\[\#19213266\] {
	--tw-gradient-to: rgba(25, 33, 50, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from),
		#19213266 var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-purple-300 {
	--tw-gradient-to: rgba(216, 180, 254, 0) var(--tw-gradient-to-position);
	--tw-gradient-stops: var(--tw-gradient-from),
		#d8b4fe var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.to-\[\#ffffff20\] {
	--tw-gradient-to: #ffffff20 var(--tw-gradient-to-position);
}
.to-blue-600 {
	--tw-gradient-to: #2563eb var(--tw-gradient-to-position);
}
.to-fuchsia-400 {
	--tw-gradient-to: #e879f9 var(--tw-gradient-to-position);
}
.to-indigo-400 {
	--tw-gradient-to: #818cf8 var(--tw-gradient-to-position);
}
.to-indigo-600 {
	--tw-gradient-to: #4f46e5 var(--tw-gradient-to-position);
}
.to-info-focus {
	--tw-gradient-to: #0284c7 var(--tw-gradient-to-position);
}
.to-orange-600 {
	--tw-gradient-to: #ea580c var(--tw-gradient-to-position);
}
.to-pink-600 {
	--tw-gradient-to: #db2777 var(--tw-gradient-to-position);
}
.to-purple-600 {
	--tw-gradient-to: #9333ea var(--tw-gradient-to-position);
}
.to-rose-500 {
	--tw-gradient-to: #f43f5e var(--tw-gradient-to-position);
}
.to-secondary {
	--tw-gradient-to: #f000b9 var(--tw-gradient-to-position);
}
.to-transparent {
	--tw-gradient-to: transparent var(--tw-gradient-to-position);
}
.bg-cover {
	background-size: cover;
}
.bg-clip-text {
	-webkit-background-clip: text;
	background-clip: text;
}
.bg-center {
	background-position: 50%;
}
.object-cover {
	object-fit: cover;
}
.object-center {
	object-position: center;
}
.object-top {
	object-position: top;
}
.p-0 {
	padding: 0;
}
.p-0\.5 {
	padding: 0.125rem;
}
.p-1 {
	padding: 0.25rem;
}
.p-1\.5 {
	padding: 0.375rem;
}
.p-2 {
	padding: 0.5rem;
}
.p-2\.5 {
	padding: 0.625rem;
}
.p-3 {
	padding: 0.75rem;
}
.p-3\.5 {
	padding: 0.875rem;
}
.p-4 {
	padding: 1rem;
}
.p-4\.5 {
	padding: 1.125rem;
}
.p-5 {
	padding: 1.25rem;
}
.p-6 {
	padding: 1.5rem;
}
.p-\[3px\] {
	padding: 3px;
}
.p-px {
	padding: 1px;
}
.px-0 {
	padding-left: 0;
	padding-right: 0;
}
.px-0\.5 {
	padding-left: 0.125rem;
	padding-right: 0.125rem;
}
.px-1 {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}
.px-1\.5 {
	padding-left: 0.375rem;
	padding-right: 0.375rem;
}
.px-2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.px-2\.5 {
	padding-left: 0.625rem;
	padding-right: 0.625rem;
}
.px-3 {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}
.px-3\.5 {
	padding-left: 0.875rem;
	padding-right: 0.875rem;
}
.px-4 {
	padding-left: 1rem;
	padding-right: 1rem;
}
.px-5 {
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}
.px-6 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.px-8 {
	padding-left: 2rem;
	padding-right: 2rem;
}
.px-9 {
	padding-left: 2.25rem;
	padding-right: 2.25rem;
}
.px-\[calc\(var\(--margin-x\)-\.25rem\)\] {
	padding-left: calc(var(--margin-x) - 0.25rem);
	padding-right: calc(var(--margin-x) - 0.25rem);
}
.px-\[calc\(var\(--margin-x\)-\.5rem\)\] {
	padding-left: calc(var(--margin-x) - 0.5rem);
	padding-right: calc(var(--margin-x) - 0.5rem);
}
.px-\[var\(--margin-x\)\] {
	padding-left: var(--margin-x);
	padding-right: var(--margin-x);
}
.py-0 {
	padding-bottom: 0;
	padding-top: 0;
}
.py-0\.5 {
	padding-bottom: 0.125rem;
	padding-top: 0.125rem;
}
.py-1 {
	padding-bottom: 0.25rem;
	padding-top: 0.25rem;
}
.py-1\.5 {
	padding-bottom: 0.375rem;
	padding-top: 0.375rem;
}
.py-10 {
	padding-bottom: 2.5rem;
	padding-top: 2.5rem;
}
.py-12 {
	padding-bottom: 3rem;
	padding-top: 3rem;
}
.py-2 {
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
}
.py-2\.5 {
	padding-bottom: 0.625rem;
	padding-top: 0.625rem;
}
.py-3 {
	padding-bottom: 0.75rem;
	padding-top: 0.75rem;
}
.py-4 {
	padding-bottom: 1rem;
	padding-top: 1rem;
}
.py-5 {
	padding-bottom: 1.25rem;
	padding-top: 1.25rem;
}
.py-6 {
	padding-bottom: 1.5rem;
	padding-top: 1.5rem;
}
.py-8 {
	padding-bottom: 2rem;
	padding-top: 2rem;
}
.pb-0 {
	padding-bottom: 0;
}
.pb-0\.5 {
	padding-bottom: 0.125rem;
}
.pb-1 {
	padding-bottom: 0.25rem;
}
.pb-12 {
	padding-bottom: 3rem;
}
.pb-2 {
	padding-bottom: 0.5rem;
}
.pb-3 {
	padding-bottom: 0.75rem;
}
.pb-4 {
	padding-bottom: 1rem;
}
.pb-5 {
	padding-bottom: 1.25rem;
}
.pb-6 {
	padding-bottom: 1.5rem;
}
.pb-8 {
	padding-bottom: 2rem;
}
.pl-4 {
	padding-left: 1rem;
}
.pl-9 {
	padding-left: 2.25rem;
}
.pl-\[var\(--main-sidebar-width\)\] {
	padding-left: var(--main-sidebar-width);
}
.pl-\[var\(--margin-x\)\] {
	padding-left: var(--margin-x);
}
.pr-1 {
	padding-right: 0.25rem;
}
.pr-2 {
	padding-right: 0.5rem;
}
.pr-3 {
	padding-right: 0.75rem;
}
.pr-6 {
	padding-right: 1.5rem;
}
.pr-8 {
	padding-right: 2rem;
}
.pr-9 {
	padding-right: 2.25rem;
}
.pt-0 {
	padding-top: 0;
}
.pt-1 {
	padding-top: 0.25rem;
}
.pt-1\.5 {
	padding-top: 0.375rem;
}
.pt-10 {
	padding-top: 2.5rem;
}
.pt-12 {
	padding-top: 3rem;
}
.pt-14 {
	padding-top: 3.5rem;
}
.pt-2 {
	padding-top: 0.5rem;
}
.pt-3 {
	padding-top: 0.75rem;
}
.pt-4 {
	padding-top: 1rem;
}
.pt-5 {
	padding-top: 1.25rem;
}
.pt-6 {
	padding-top: 1.5rem;
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.font-inter {
	font-family: Inter, sans-serif;
}
.text-2xl {
	font-size: 1.5rem;
	line-height: 2rem;
}
.text-3xl {
	font-size: 1.875rem;
	line-height: 2.25rem;
}
.text-4xl {
	font-size: 2.25rem;
	line-height: 2.5rem;
}
.text-5xl {
	font-size: 3rem;
	line-height: 1;
}
.text-6xl {
	font-size: 3.75rem;
	line-height: 1;
}
.text-7xl {
	font-size: 4.5rem;
	line-height: 1;
}
.text-8xl {
	font-size: 6rem;
	line-height: 1;
}
.text-base {
	font-size: 1rem;
	line-height: 1.5rem;
}
.text-lg {
	font-size: 1.125rem;
	line-height: 1.75rem;
}
.text-sm {
	font-size: 0.875rem;
	line-height: 1.25rem;
}
.text-sm\+ {
	font-size: 0.9375rem;
	line-height: 1.375rem;
}
.text-tiny {
	font-size: 0.625rem;
	line-height: 0.8125rem;
}
.text-tiny\+ {
	font-size: 0.6875rem;
	line-height: 0.875rem;
}
.text-xl {
	font-size: 1.25rem;
	line-height: 1.75rem;
}
.text-xs {
	font-size: 0.75rem;
	line-height: 1rem;
}
.text-xs\+ {
	font-size: 0.8125rem;
	line-height: 1.125rem;
}
.font-bold {
	font-weight: 700;
}
.font-light {
	font-weight: 300;
}
.font-medium {
	font-weight: 500;
}
.font-normal {
	font-weight: 400;
}
.font-semibold {
	font-weight: 600;
}
.uppercase {
	text-transform: uppercase;
}
.italic {
	font-style: italic;
}
.leading-6 {
	line-height: 1.5rem;
}
.leading-none {
	line-height: 1;
}
.leading-normal {
	line-height: 1.5;
}
.leading-relaxed {
	line-height: 1.625;
}
.leading-snug {
	line-height: 1.375;
}
.leading-tight {
	line-height: 1.25;
}
.tracking-tight {
	letter-spacing: -0.025em;
}
.tracking-wide {
	letter-spacing: 0.025em;
}
.tracking-wider {
	letter-spacing: 0.05em;
}
.text-accent-light {
	color: #818cf8;
}
.text-amber-400 {
	color: #fbbf24;
}
.text-amber-50 {
	color: #fffbeb;
}
.text-error {
	color: #ff5724;
}
.text-indigo-100 {
	color: #e0e7ff;
}
.text-indigo-50 {
	color: #eef2ff;
}
.text-info {
	color: #0ea5e9;
}
.text-navy-100 {
	color: #c2c9d6;
}
.text-navy-700 {
	color: #26334d;
}
.text-pink-100 {
	color: #fce7f3;
}
.text-primary {
	color: #4f46e5;
}
.text-secondary {
	color: #f000b9;
}
.text-secondary-light {
	color: #ff57d8;
}
.text-sky-100 {
	color: #e0f2fe;
}
.text-slate-150 {
	color: #e9eef5;
}
.text-slate-200 {
	color: #e2e8f0;
}
.text-slate-400 {
	color: #94a3b8;
}
.text-slate-500 {
	color: #64748b;
}
.text-slate-600 {
	color: #475569;
}
.text-slate-700 {
	color: #334155;
}
.text-slate-800 {
	color: #1e293b;
}
.text-slate-900 {
	color: #0f172a;
}
.text-success {
	color: #10b981;
}
.text-transparent {
	color: transparent;
}
.text-warning {
	color: #ff9800;
}
.text-white {
	color: #fff;
}
.text-yellow-100 {
	color: #fef9c3;
}
.text-yellow-500 {
	color: #eab308;
}
.underline {
	text-decoration-line: underline;
}
.overline {
	text-decoration-line: overline;
}
.line-through {
	text-decoration-line: line-through;
}
.no-underline {
	text-decoration-line: none;
}
.decoration-info {
	text-decoration-color: #0ea5e9;
}
.decoration-primary {
	text-decoration-color: #4f46e5;
}
.decoration-secondary {
	text-decoration-color: #f000b9;
}
.decoration-double {
	text-decoration-style: double;
}
.decoration-dotted {
	text-decoration-style: dotted;
}
.decoration-dashed {
	text-decoration-style: dashed;
}
.decoration-wavy {
	text-decoration-style: wavy;
}
.decoration-2 {
	text-decoration-thickness: 2px;
}
.placeholder-slate-400::placeholder {
	color: #94a3b8;
}
.opacity-0 {
	opacity: 0;
}
.opacity-15 {
	opacity: 0.15;
}
.opacity-20 {
	opacity: 0.2;
}
.opacity-40 {
	opacity: 0.4;
}
.opacity-50 {
	opacity: 0.5;
}
.opacity-75 {
	opacity: 0.75;
}
.opacity-80 {
	opacity: 0.8;
}
.shadow {
	--tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
		0 1px 2px -1px rgba(0, 0, 0, 0.1);
	--tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
		0 1px 2px -1px var(--tw-shadow-color);
}
.shadow,
.shadow-lg {
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
	--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
		0 4px 6px -4px var(--tw-shadow-color);
}
.shadow-none {
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
}
.shadow-none,
.shadow-sm {
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
	--tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	--tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
}
.shadow-soft {
	--tw-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
	--tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
}
.shadow-soft,
.shadow-xl {
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
	--tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 8px 10px -6px rgba(0, 0, 0, 0.1);
	--tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
		0 8px 10px -6px var(--tw-shadow-color);
}
.shadow-error\/50 {
	--tw-shadow-color: rgba(255, 87, 36, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.shadow-info\/50 {
	--tw-shadow-color: rgba(14, 165, 233, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.shadow-primary\/50 {
	--tw-shadow-color: rgba(79, 70, 229, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.shadow-secondary\/50 {
	--tw-shadow-color: rgba(240, 0, 185, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.shadow-slate-200\/50 {
	--tw-shadow-color: rgba(226, 232, 240, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.shadow-success\/50 {
	--tw-shadow-color: rgba(16, 185, 129, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.shadow-warning\/50 {
	--tw-shadow-color: rgba(255, 152, 0, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.outline-none {
	outline: 2px solid transparent;
	outline-offset: 2px;
}
.outline {
	outline-style: solid;
}
.ring {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
		var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0
		calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.ring,
.ring-1 {
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		var(--tw-shadow, 0 0 #0000);
}
.ring-1 {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
		var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0
		calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.ring-2 {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
		var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0
		calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		var(--tw-shadow, 0 0 #0000);
}
.ring-primary {
	--tw-ring-color: #4f46e5;
}
.ring-primary\/50 {
	--tw-ring-color: rgba(79, 70, 229, 0.5);
}
.ring-white {
	--tw-ring-color: #fff;
}
.blur {
	--tw-blur: blur(8px);
}
.blur,
.filter {
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
		var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
		var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur {
	--tw-backdrop-blur: blur(8px);
	-webkit-backdrop-filter: var(--tw-backdrop-blur)
		var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
		var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
		var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
		var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
		var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
		var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
		var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
		var(--tw-backdrop-sepia);
}
.transition-\[color\2c padding-left\] {
	transition-duration: 0.15s;
	transition-property: color, padding-left;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition-\[padding\2c width\] {
	transition-duration: 0.15s;
	transition-property: padding, width;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition-all {
	transition-duration: 0.15s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition-colors {
	transition-duration: 0.15s;
	transition-property: color, background-color, border-color,
		text-decoration-color, fill, stroke;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition-opacity {
	transition-duration: 0.15s;
	transition-property: opacity;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition-shadow {
	transition-duration: 0.15s;
	transition-property: box-shadow;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.transition-transform {
	transition-duration: 0.15s;
	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.duration-100 {
	transition-duration: 0.1s;
}
.duration-200 {
	transition-duration: 0.2s;
}
.duration-300 {
	transition-duration: 0.3s;
}
.duration-500 {
	transition-duration: 0.5s;
}
.duration-\[\.25s\] {
	transition-duration: 0.25s;
}
.ease-in-out {
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.\[--fp-grid\:2\] {
	--fp-grid: 2;
}
.\[--fp-grid\:3\] {
	--fp-grid: 3;
}
.\[--fp-grid\:4\] {
	--fp-grid: 4;
}
.\[--line\:\.5rem\] {
	--line: 0.5rem;
}
.\[--line\:1px\] {
	--line: 1px;
}
.\[--range-thumb-size\:12px\] {
	--range-thumb-size: 12px;
}
.\[--range-thumb-size\:24px\] {
	--range-thumb-size: 24px;
}
.\[--range-track-h\:3px\] {
	--range-track-h: 3px;
}
.\[--range-track-h\:8px\] {
	--range-track-h: 8px;
}
.\[--size\:\.75rem\] {
	--size: 0.75rem;
}
.\[--size\:1\.5rem\] {
	--size: 1.5rem;
}
.\[--size\:1rem\] {
	--size: 1rem;
}
.\[--size\:2\.75rem\] {
	--size: 2.75rem;
}
.\[--thumb-border\:3px\] {
	--thumb-border: 3px;
}
.\[--thumb-border\:4px\] {
	--thumb-border: 4px;
}
.\[--thumb-border\:5px\] {
	--thumb-border: 5px;
}
.\[--thumb-border\:6px\] {
	--thumb-border: 6px;
}
.placeholder\:text-slate-400::placeholder {
	color: #94a3b8;
}
.placeholder\:text-slate-400\/70::placeholder {
	color: rgba(148, 163, 184, 0.7);
}
.placeholder\:text-slate-500::placeholder {
	color: #64748b;
}
.before\:rounded-full:before {
	border-radius: 9999px;
	content: var(--tw-content);
}
.before\:rounded-md:before {
	border-radius: 0.375rem;
	content: var(--tw-content);
}
.before\:\!bg-error:before {
	background-color: #ff5724 !important;
	content: var(--tw-content);
}
.before\:\!bg-info:before {
	background-color: #0ea5e9 !important;
	content: var(--tw-content);
}
.before\:\!bg-success:before {
	background-color: #10b981 !important;
	content: var(--tw-content);
}
.before\:\!bg-warning:before {
	background-color: #ff9800 !important;
	content: var(--tw-content);
}
.before\:bg-primary:before {
	background-color: #4f46e5;
	content: var(--tw-content);
}
.before\:bg-secondary:before {
	background-color: #f000b9;
	content: var(--tw-content);
}
.before\:bg-slate-200:before {
	background-color: #e2e8f0;
	content: var(--tw-content);
}
.before\:bg-slate-300:before {
	background-color: #cbd5e1;
	content: var(--tw-content);
}
.before\:bg-slate-50:before {
	background-color: #f8fafc;
	content: var(--tw-content);
}
.before\:bg-slate-500:before {
	background-color: #64748b;
	content: var(--tw-content);
}
.checked\:\!border-error:checked {
	border-color: #ff5724 !important;
}
.checked\:\!border-info:checked {
	border-color: #0ea5e9 !important;
}
.checked\:\!border-success:checked {
	border-color: #10b981 !important;
}
.checked\:\!border-warning:checked {
	border-color: #ff9800 !important;
}
.checked\:border-primary:checked {
	border-color: #4f46e5;
}
.checked\:border-secondary:checked {
	border-color: #f000b9;
}
.checked\:border-slate-500:checked {
	border-color: #64748b;
}
.checked\:\!bg-error:checked {
	background-color: #ff5724 !important;
}
.checked\:\!bg-info:checked {
	background-color: #0ea5e9 !important;
}
.checked\:\!bg-success:checked {
	background-color: #10b981 !important;
}
.checked\:\!bg-warning:checked {
	background-color: #ff9800 !important;
}
.checked\:bg-error:checked {
	background-color: #ff5724;
}
.checked\:bg-info:checked {
	background-color: #0ea5e9;
}
.checked\:bg-primary:checked {
	background-color: #4f46e5;
}
.checked\:bg-secondary:checked {
	background-color: #f000b9;
}
.checked\:bg-slate-500:checked {
	background-color: #64748b;
}
.checked\:bg-success:checked {
	background-color: #10b981;
}
.checked\:bg-warning:checked {
	background-color: #ff9800;
}
.checked\:before\:\!bg-error:checked:before {
	background-color: #ff5724 !important;
	content: var(--tw-content);
}
.checked\:before\:\!bg-info:checked:before {
	background-color: #0ea5e9 !important;
	content: var(--tw-content);
}
.checked\:before\:\!bg-success:checked:before {
	background-color: #10b981 !important;
	content: var(--tw-content);
}
.checked\:before\:\!bg-warning:checked:before {
	background-color: #ff9800 !important;
	content: var(--tw-content);
}
.checked\:before\:bg-primary:checked:before {
	background-color: #4f46e5;
	content: var(--tw-content);
}
.checked\:before\:bg-secondary:checked:before {
	background-color: #f000b9;
	content: var(--tw-content);
}
.checked\:before\:bg-slate-500:checked:before {
	background-color: #64748b;
	content: var(--tw-content);
}
.checked\:before\:bg-white:checked:before {
	background-color: #fff;
	content: var(--tw-content);
}
.focus-within\:\!border-primary:focus-within {
	border-color: #4f46e5 !important;
}
.hover\:z-10:hover {
	z-index: 10;
}
.hover\:rotate-\[360deg\]:hover {
	--tw-rotate: 360deg;
}
.hover\:rotate-\[360deg\]:hover,
.hover\:scale-110:hover {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:scale-110:hover {
	--tw-scale-x: 1.1;
	--tw-scale-y: 1.1;
}
.hover\:\!border-error:hover {
	border-color: #ff5724 !important;
}
.hover\:\!border-info:hover {
	border-color: #0ea5e9 !important;
}
.hover\:\!border-success:hover {
	border-color: #10b981 !important;
}
.hover\:\!border-warning:hover {
	border-color: #ff9800 !important;
}
.hover\:border-primary:hover {
	border-color: #4f46e5;
}
.hover\:border-secondary:hover {
	border-color: #f000b9;
}
.hover\:border-slate-400:hover {
	border-color: #94a3b8;
}
.hover\:border-slate-500:hover {
	border-color: #64748b;
}
.hover\:bg-black\/30:hover {
	background-color: rgba(0, 0, 0, 0.3);
}
.hover\:bg-error:hover {
	background-color: #ff5724;
}
.hover\:bg-error-focus:hover {
	background-color: #f03000;
}
.hover\:bg-error\/20:hover {
	background-color: rgba(255, 87, 36, 0.2);
}
.hover\:bg-info:hover {
	background-color: #0ea5e9;
}
.hover\:bg-info-focus:hover {
	background-color: #0284c7;
}
.hover\:bg-info\/20:hover {
	background-color: rgba(14, 165, 233, 0.2);
}
.hover\:bg-navy-450:hover {
	background-color: #465675;
}
.hover\:bg-primary:hover {
	background-color: #4f46e5;
}
.hover\:bg-primary-focus:hover {
	background-color: #4338ca;
}
.hover\:bg-primary\/10:hover {
	background-color: rgba(79, 70, 229, 0.1);
}
.hover\:bg-primary\/20:hover {
	background-color: rgba(79, 70, 229, 0.2);
}
.hover\:bg-secondary:hover {
	background-color: #f000b9;
}
.hover\:bg-secondary-focus:hover {
	background-color: #bd0090;
}
.hover\:bg-secondary\/20:hover {
	background-color: rgba(240, 0, 185, 0.2);
}
.hover\:bg-slate-100:hover {
	background-color: #f1f5f9;
}
.hover\:bg-slate-150:hover {
	background-color: #e9eef5;
}
.hover\:bg-slate-200:hover {
	background-color: #e2e8f0;
}
.hover\:bg-slate-300:hover {
	background-color: #cbd5e1;
}
.hover\:bg-slate-300\/20:hover {
	background-color: rgba(203, 213, 225, 0.2);
}
.hover\:bg-success:hover {
	background-color: #10b981;
}
.hover\:bg-success-focus:hover {
	background-color: #059669;
}
.hover\:bg-success\/20:hover {
	background-color: rgba(16, 185, 129, 0.2);
}
.hover\:bg-warning:hover {
	background-color: #ff9800;
}
.hover\:bg-warning-focus:hover {
	background-color: #e68200;
}
.hover\:bg-warning\/20:hover {
	background-color: rgba(255, 152, 0, 0.2);
}
.hover\:bg-white\/20:hover {
	background-color: hsla(0, 0%, 100%, 0.2);
}
.hover\:bg-white\/30:hover {
	background-color: hsla(0, 0%, 100%, 0.3);
}
.hover\:pl-4:hover {
	padding-left: 1rem;
}
.hover\:text-error:hover {
	color: #ff5724;
}
.hover\:text-info:hover {
	color: #0ea5e9;
}
.hover\:text-primary:hover {
	color: #4f46e5;
}
.hover\:text-primary-focus:hover {
	color: #4338ca;
}
.hover\:text-primary\/70:hover {
	color: rgba(79, 70, 229, 0.7);
}
.hover\:text-secondary:hover {
	color: #f000b9;
}
.hover\:text-slate-700:hover {
	color: #334155;
}
.hover\:text-slate-800:hover {
	color: #1e293b;
}
.hover\:text-success:hover {
	color: #10b981;
}
.hover\:text-white:hover {
	color: #fff;
}
.hover\:underline:hover {
	text-decoration-line: underline;
}
.hover\:shadow-lg:hover {
	--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
		0 4px 6px -4px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-error\/50:hover {
	--tw-shadow-color: rgba(255, 87, 36, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.hover\:shadow-info\/50:hover {
	--tw-shadow-color: rgba(14, 165, 233, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.hover\:shadow-primary\/50:hover {
	--tw-shadow-color: rgba(79, 70, 229, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.hover\:shadow-secondary\/50:hover {
	--tw-shadow-color: rgba(240, 0, 185, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.hover\:shadow-slate-200\/50:hover {
	--tw-shadow-color: rgba(226, 232, 240, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.hover\:shadow-success\/50:hover {
	--tw-shadow-color: rgba(16, 185, 129, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.hover\:shadow-warning\/50:hover {
	--tw-shadow-color: rgba(255, 152, 0, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.focus\:z-10:focus {
	z-index: 10;
}
.focus\:\!border-error:focus {
	border-color: #ff5724 !important;
}
.focus\:\!border-info:focus {
	border-color: #0ea5e9 !important;
}
.focus\:\!border-success:focus {
	border-color: #10b981 !important;
}
.focus\:\!border-warning:focus {
	border-color: #ff9800 !important;
}
.focus\:border-primary:focus {
	border-color: #4f46e5;
}
.focus\:border-secondary:focus {
	border-color: #f000b9;
}
.focus\:border-slate-500:focus {
	border-color: #64748b;
}
.focus\:bg-black\/30:focus {
	background-color: rgba(0, 0, 0, 0.3);
}
.focus\:bg-error:focus {
	background-color: #ff5724;
}
.focus\:bg-error-focus:focus {
	background-color: #f03000;
}
.focus\:bg-error\/20:focus {
	background-color: rgba(255, 87, 36, 0.2);
}
.focus\:bg-info:focus {
	background-color: #0ea5e9;
}
.focus\:bg-info-focus:focus {
	background-color: #0284c7;
}
.focus\:bg-info\/20:focus {
	background-color: rgba(14, 165, 233, 0.2);
}
.focus\:bg-navy-450:focus {
	background-color: #465675;
}
.focus\:bg-primary:focus {
	background-color: #4f46e5;
}
.focus\:bg-primary-focus:focus {
	background-color: #4338ca;
}
.focus\:bg-primary\/10:focus {
	background-color: rgba(79, 70, 229, 0.1);
}
.focus\:bg-primary\/20:focus {
	background-color: rgba(79, 70, 229, 0.2);
}
.focus\:bg-secondary:focus {
	background-color: #f000b9;
}
.focus\:bg-secondary-focus:focus {
	background-color: #bd0090;
}
.focus\:bg-secondary\/20:focus {
	background-color: rgba(240, 0, 185, 0.2);
}
.focus\:bg-slate-100:focus {
	background-color: #f1f5f9;
}
.focus\:bg-slate-150:focus {
	background-color: #e9eef5;
}
.focus\:bg-slate-200:focus {
	background-color: #e2e8f0;
}
.focus\:bg-slate-300:focus {
	background-color: #cbd5e1;
}
.focus\:bg-slate-300\/20:focus {
	background-color: rgba(203, 213, 225, 0.2);
}
.focus\:bg-success:focus {
	background-color: #10b981;
}
.focus\:bg-success-focus:focus {
	background-color: #059669;
}
.focus\:bg-success\/20:focus {
	background-color: rgba(16, 185, 129, 0.2);
}
.focus\:bg-warning:focus {
	background-color: #ff9800;
}
.focus\:bg-warning-focus:focus {
	background-color: #e68200;
}
.focus\:bg-warning\/20:focus {
	background-color: rgba(255, 152, 0, 0.2);
}
.focus\:bg-white\/20:focus {
	background-color: hsla(0, 0%, 100%, 0.2);
}
.focus\:bg-white\/30:focus {
	background-color: hsla(0, 0%, 100%, 0.3);
}
.focus\:text-error:focus {
	color: #ff5724;
}
.focus\:text-info:focus {
	color: #0ea5e9;
}
.focus\:text-navy-800:focus {
	color: #202b40;
}
.focus\:text-primary:focus {
	color: #4f46e5;
}
.focus\:text-primary\/70:focus {
	color: rgba(79, 70, 229, 0.7);
}
.focus\:text-secondary:focus {
	color: #f000b9;
}
.focus\:text-slate-800:focus {
	color: #1e293b;
}
.focus\:text-success:focus {
	color: #10b981;
}
.focus\:text-white:focus {
	color: #fff;
}
.focus\:underline:focus {
	text-decoration-line: underline;
}
.focus\:opacity-100:focus {
	opacity: 1;
}
.focus\:shadow-lg:focus {
	--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -4px rgba(0, 0, 0, 0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
		0 4px 6px -4px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow-error\/50:focus {
	--tw-shadow-color: rgba(255, 87, 36, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.focus\:shadow-info\/50:focus {
	--tw-shadow-color: rgba(14, 165, 233, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.focus\:shadow-primary\/50:focus {
	--tw-shadow-color: rgba(79, 70, 229, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.focus\:shadow-slate-200\/50:focus {
	--tw-shadow-color: rgba(226, 232, 240, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.focus\:shadow-success\/50:focus {
	--tw-shadow-color: rgba(16, 185, 129, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.focus\:shadow-warning\/50:focus {
	--tw-shadow-color: rgba(255, 152, 0, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
.focus\:outline-none:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
}
.focus\:ring:focus {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
		var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0
		calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		var(--tw-shadow, 0 0 #0000);
}
.active\:bg-error-focus\/90:active {
	background-color: rgba(240, 48, 0, 0.9);
}
.active\:bg-error\/25:active {
	background-color: rgba(255, 87, 36, 0.25);
}
.active\:bg-error\/90:active {
	background-color: rgba(255, 87, 36, 0.9);
}
.active\:bg-info-focus\/90:active {
	background-color: rgba(2, 132, 199, 0.9);
}
.active\:bg-info\/25:active {
	background-color: rgba(14, 165, 233, 0.25);
}
.active\:bg-info\/90:active {
	background-color: rgba(14, 165, 233, 0.9);
}
.active\:bg-navy-450\/90:active {
	background-color: rgba(70, 86, 117, 0.9);
}
.active\:bg-primary-focus\/90:active {
	background-color: rgba(67, 56, 202, 0.9);
}
.active\:bg-primary\/25:active {
	background-color: rgba(79, 70, 229, 0.25);
}
.active\:bg-primary\/90:active {
	background-color: rgba(79, 70, 229, 0.9);
}
.active\:bg-secondary-focus\/90:active {
	background-color: rgba(189, 0, 144, 0.9);
}
.active\:bg-secondary\/25:active {
	background-color: rgba(240, 0, 185, 0.25);
}
.active\:bg-secondary\/90:active {
	background-color: rgba(240, 0, 185, 0.9);
}
.active\:bg-slate-150\/80:active {
	background-color: rgba(233, 238, 245, 0.8);
}
.active\:bg-slate-200\/80:active {
	background-color: rgba(226, 232, 240, 0.8);
}
.active\:bg-slate-300\/25:active {
	background-color: rgba(203, 213, 225, 0.25);
}
.active\:bg-slate-300\/80:active {
	background-color: rgba(203, 213, 225, 0.8);
}
.active\:bg-success-focus\/90:active {
	background-color: rgba(5, 150, 105, 0.9);
}
.active\:bg-success\/25:active {
	background-color: rgba(16, 185, 129, 0.25);
}
.active\:bg-success\/90:active {
	background-color: rgba(16, 185, 129, 0.9);
}
.active\:bg-warning-focus\/90:active {
	background-color: rgba(230, 130, 0, 0.9);
}
.active\:bg-warning\/25:active {
	background-color: rgba(255, 152, 0, 0.25);
}
.active\:bg-warning\/90:active {
	background-color: rgba(255, 152, 0, 0.9);
}
.active\:bg-white\/25:active {
	background-color: hsla(0, 0%, 100%, 0.25);
}
.disabled\:pointer-events-none:disabled {
	pointer-events: none;
}
.disabled\:select-none:disabled {
	-webkit-user-select: none;
	user-select: none;
}
.disabled\:border-none:disabled {
	border-style: none;
}
.disabled\:border-slate-300:disabled {
	border-color: #cbd5e1;
}
.disabled\:bg-zinc-100:disabled {
	background-color: #f4f4f5;
}
.disabled\:opacity-60:disabled {
	opacity: 0.6;
}
.group:hover .group-hover\:text-primary {
	color: #4f46e5;
}
.group:hover .group-hover\:text-slate-500 {
	color: #64748b;
}
.group:hover .group-hover\:opacity-100 {
	opacity: 1;
}
.group:focus .group-focus\:text-primary {
	color: #4f46e5;
}
.group:focus .group-focus\:text-slate-500 {
	color: #64748b;
}
.peer:focus ~ .peer-focus\:text-primary {
	color: #4f46e5;
}
:is(.dark .dark\:divide-navy-500) > :not([hidden]) ~ :not([hidden]) {
	border-color: #384766;
}
:is(.dark .dark\:border-accent) {
	border-color: #5f5af6;
}
:is(.dark .dark\:border-accent-light) {
	border-color: #818cf8;
}
:is(.dark .dark\:border-accent-light\/30) {
	border-color: rgba(129, 140, 248, 0.3);
}
:is(.dark .dark\:border-accent\/30) {
	border-color: rgba(95, 90, 246, 0.3);
}
:is(.dark .dark\:border-navy-300) {
	border-color: #697a9b;
}
:is(.dark .dark\:border-navy-400) {
	border-color: #5c6b8a;
}
:is(.dark .dark\:border-navy-450) {
	border-color: #465675;
}
:is(.dark .dark\:border-navy-500) {
	border-color: #384766;
}
:is(.dark .dark\:border-navy-600) {
	border-color: #313e59;
}
:is(.dark .dark\:border-navy-700) {
	border-color: #26334d;
}
:is(.dark .dark\:border-navy-800) {
	border-color: #202b40;
}
:is(.dark .dark\:border-navy-900) {
	border-color: #192132;
}
:is(.dark .dark\:border-secondary) {
	border-color: #f000b9;
}
:is(.dark .dark\:border-secondary-light) {
	border-color: #ff57d8;
}
:is(.dark .dark\:border-secondary-light\/30) {
	border-color: rgba(255, 87, 216, 0.3);
}
:is(.dark .dark\:border-slate-700) {
	border-color: #334155;
}
:is(.dark .dark\:border-b-navy-500) {
	border-bottom-color: #384766;
}
:is(.dark .dark\:border-r-accent) {
	border-right-color: #5f5af6;
}
:is(.dark .dark\:border-r-navy-300) {
	border-right-color: #697a9b;
}
:is(.dark .dark\:border-r-transparent) {
	border-right-color: transparent;
}
:is(.dark .dark\:bg-accent) {
	background-color: #5f5af6;
}
:is(.dark .dark\:bg-accent-focus) {
	background-color: #4d47f5;
}
:is(.dark .dark\:bg-accent-light\/10) {
	background-color: rgba(129, 140, 248, 0.1);
}
:is(.dark .dark\:bg-accent-light\/15) {
	background-color: rgba(129, 140, 248, 0.15);
}
:is(.dark .dark\:bg-accent\/10) {
	background-color: rgba(95, 90, 246, 0.1);
}
:is(.dark .dark\:bg-accent\/25) {
	background-color: rgba(95, 90, 246, 0.25);
}
:is(.dark .dark\:bg-error\/15) {
	background-color: rgba(255, 87, 36, 0.15);
}
:is(.dark .dark\:bg-error\/25) {
	background-color: rgba(255, 87, 36, 0.25);
}
:is(.dark .dark\:bg-info) {
	background-color: #0ea5e9;
}
:is(.dark .dark\:bg-info\/15) {
	background-color: rgba(14, 165, 233, 0.15);
}
:is(.dark .dark\:bg-info\/25) {
	background-color: rgba(14, 165, 233, 0.25);
}
:is(.dark .dark\:bg-navy-300) {
	background-color: #697a9b;
}
:is(.dark .dark\:bg-navy-400) {
	background-color: #5c6b8a;
}
:is(.dark .dark\:bg-navy-450) {
	background-color: #465675;
}
:is(.dark .dark\:bg-navy-500) {
	background-color: #384766;
}
:is(.dark .dark\:bg-navy-600) {
	background-color: #313e59;
}
:is(.dark .dark\:bg-navy-700) {
	background-color: #26334d;
}
:is(.dark .dark\:bg-navy-750) {
	background-color: #222e45;
}
:is(.dark .dark\:bg-navy-800) {
	background-color: #202b40;
}
:is(.dark .dark\:bg-navy-900) {
	background-color: #192132;
}
:is(.dark .dark\:bg-navy-900\/90) {
	background-color: rgba(25, 33, 50, 0.9);
}
:is(.dark .dark\:bg-secondary) {
	background-color: #f000b9;
}
:is(.dark .dark\:bg-secondary-light) {
	background-color: #ff57d8;
}
:is(.dark .dark\:bg-secondary-light\/10) {
	background-color: rgba(255, 87, 216, 0.1);
}
:is(.dark .dark\:bg-secondary-light\/15) {
	background-color: rgba(255, 87, 216, 0.15);
}
:is(.dark .dark\:bg-secondary-light\/25) {
	background-color: rgba(255, 87, 216, 0.25);
}
:is(.dark .dark\:bg-secondary\/15) {
	background-color: rgba(240, 0, 185, 0.15);
}
:is(.dark .dark\:bg-secondary\/25) {
	background-color: rgba(240, 0, 185, 0.25);
}
:is(.dark .dark\:bg-success) {
	background-color: #10b981;
}
:is(.dark .dark\:bg-success\/15) {
	background-color: rgba(16, 185, 129, 0.15);
}
:is(.dark .dark\:bg-success\/25) {
	background-color: rgba(16, 185, 129, 0.25);
}
:is(.dark .dark\:bg-transparent) {
	background-color: transparent;
}
:is(.dark .dark\:bg-warning) {
	background-color: #ff9800;
}
:is(.dark .dark\:bg-warning\/15) {
	background-color: rgba(255, 152, 0, 0.15);
}
:is(.dark .dark\:bg-warning\/25) {
	background-color: rgba(255, 152, 0, 0.25);
}
:is(.dark .dark\:bg-white\/20) {
	background-color: hsla(0, 0%, 100%, 0.2);
}
:is(.dark .dark\:text-accent) {
	color: #5f5af6;
}
:is(.dark .dark\:text-accent-light) {
	color: #818cf8;
}
:is(.dark .dark\:text-accent-light\/80) {
	color: rgba(129, 140, 248, 0.8);
}
:is(.dark .dark\:text-navy-100) {
	color: #c2c9d6;
}
:is(.dark .dark\:text-navy-200) {
	color: #a3adc2;
}
:is(.dark .dark\:text-navy-300) {
	color: #697a9b;
}
:is(.dark .dark\:text-navy-50) {
	color: #e7e9ef;
}
:is(.dark .dark\:text-navy-600) {
	color: #313e59;
}
:is(.dark .dark\:text-navy-700) {
	color: #26334d;
}
:is(.dark .dark\:text-secondary-light) {
	color: #ff57d8;
}
:is(.dark .dark\:text-white) {
	color: #fff;
}
:is(.dark .dark\:placeholder-navy-300)::placeholder {
	color: #697a9b;
}
:is(.dark .dark\:shadow-none) {
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
:is(.dark .dark\:shadow-soft-dark) {
	--tw-shadow: 0 3px 10px 0 rgba(25, 33, 50, 0.3);
	--tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
:is(.dark .dark\:shadow-accent\/50) {
	--tw-shadow-color: rgba(95, 90, 246, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
:is(.dark .dark\:shadow-navy-450\/50) {
	--tw-shadow-color: rgba(70, 86, 117, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
:is(.dark .dark\:ring-accent) {
	--tw-ring-color: #5f5af6;
}
:is(.dark .dark\:ring-accent\/50) {
	--tw-ring-color: rgba(95, 90, 246, 0.5);
}
:is(.dark .dark\:ring-navy-700) {
	--tw-ring-color: #26334d;
}
:is(.dark .dark\:placeholder\:text-navy-200)::placeholder {
	color: #a3adc2;
}
:is(.dark .dark\:placeholder\:text-navy-300)::placeholder {
	color: #697a9b;
}
:is(.dark .dark\:before\:bg-accent):before {
	background-color: #5f5af6;
	content: var(--tw-content);
}
:is(.dark .dark\:before\:bg-navy-200):before {
	background-color: #a3adc2;
	content: var(--tw-content);
}
:is(.dark .dark\:before\:bg-navy-300):before {
	background-color: #697a9b;
	content: var(--tw-content);
}
:is(.dark .dark\:before\:bg-navy-400):before {
	background-color: #5c6b8a;
	content: var(--tw-content);
}
:is(.dark .dark\:before\:bg-navy-500):before {
	background-color: #384766;
	content: var(--tw-content);
}
:is(.dark .dark\:before\:bg-secondary-light):before {
	background-color: #ff57d8;
	content: var(--tw-content);
}
:is(.dark .dark\:checked\:border-accent:checked) {
	border-color: #5f5af6;
}
:is(.dark .dark\:checked\:border-navy-200:checked) {
	border-color: #a3adc2;
}
:is(.dark .dark\:checked\:border-navy-400:checked) {
	border-color: #5c6b8a;
}
:is(.dark .dark\:checked\:border-secondary-light:checked) {
	border-color: #ff57d8;
}
:is(.dark .dark\:checked\:bg-accent:checked) {
	background-color: #5f5af6;
}
:is(.dark .dark\:checked\:bg-navy-400:checked) {
	background-color: #5c6b8a;
}
:is(.dark .dark\:checked\:bg-secondary-light:checked) {
	background-color: #ff57d8;
}
:is(.dark .dark\:checked\:before\:bg-accent:checked):before {
	background-color: #5f5af6;
	content: var(--tw-content);
}
:is(.dark .dark\:checked\:before\:bg-navy-200:checked):before {
	background-color: #a3adc2;
	content: var(--tw-content);
}
:is(.dark .dark\:checked\:before\:bg-secondary-light:checked):before {
	background-color: #ff57d8;
	content: var(--tw-content);
}
:is(.dark .dark\:checked\:before\:bg-white:checked):before {
	background-color: #fff;
	content: var(--tw-content);
}
:is(.dark .dark\:focus-within\:\!border-accent:focus-within) {
	border-color: #5f5af6 !important;
}
:is(.dark .dark\:hover\:border-accent:hover) {
	border-color: #5f5af6;
}
:is(.dark .dark\:hover\:border-navy-200:hover) {
	border-color: #a3adc2;
}
:is(.dark .dark\:hover\:border-navy-400:hover) {
	border-color: #5c6b8a;
}
:is(.dark .dark\:hover\:border-secondary-light:hover) {
	border-color: #ff57d8;
}
:is(.dark .dark\:hover\:bg-accent:hover) {
	background-color: #5f5af6;
}
:is(.dark .dark\:hover\:bg-accent-focus:hover) {
	background-color: #4d47f5;
}
:is(.dark .dark\:hover\:bg-accent-light\/10:hover) {
	background-color: rgba(129, 140, 248, 0.1);
}
:is(.dark .dark\:hover\:bg-accent-light\/15:hover) {
	background-color: rgba(129, 140, 248, 0.15);
}
:is(.dark .dark\:hover\:bg-accent-light\/20:hover) {
	background-color: rgba(129, 140, 248, 0.2);
}
:is(.dark .dark\:hover\:bg-navy-300\/20:hover) {
	background-color: rgba(105, 122, 155, 0.2);
}
:is(.dark .dark\:hover\:bg-navy-450:hover) {
	background-color: #465675;
}
:is(.dark .dark\:hover\:bg-navy-500:hover) {
	background-color: #384766;
}
:is(.dark .dark\:hover\:bg-navy-600:hover) {
	background-color: #313e59;
}
:is(.dark .dark\:hover\:bg-navy-900:hover) {
	background-color: #192132;
}
:is(.dark .dark\:hover\:bg-secondary:hover) {
	background-color: #f000b9;
}
:is(.dark .dark\:hover\:bg-secondary-light\/20:hover) {
	background-color: rgba(255, 87, 216, 0.2);
}
:is(.dark .dark\:hover\:bg-white\/30:hover) {
	background-color: hsla(0, 0%, 100%, 0.3);
}
:is(.dark .dark\:hover\:text-accent:hover) {
	color: #5f5af6;
}
:is(.dark .dark\:hover\:text-accent-light:hover) {
	color: #818cf8;
}
:is(.dark .dark\:hover\:text-accent-light\/70:hover) {
	color: rgba(129, 140, 248, 0.7);
}
:is(.dark .dark\:hover\:text-navy-100:hover) {
	color: #c2c9d6;
}
:is(.dark .dark\:hover\:text-navy-50:hover) {
	color: #e7e9ef;
}
:is(.dark .dark\:hover\:text-secondary-light:hover) {
	color: #ff57d8;
}
:is(.dark .dark\:hover\:text-white:hover) {
	color: #fff;
}
:is(.dark .dark\:hover\:shadow-accent\/50:hover) {
	--tw-shadow-color: rgba(95, 90, 246, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
:is(.dark .dark\:hover\:shadow-navy-450\/50:hover) {
	--tw-shadow-color: rgba(70, 86, 117, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
:is(.dark .dark\:focus\:border-accent:focus) {
	border-color: #5f5af6;
}
:is(.dark .dark\:focus\:border-navy-200:focus) {
	border-color: #a3adc2;
}
:is(.dark .dark\:focus\:border-secondary-light:focus) {
	border-color: #ff57d8;
}
:is(.dark .dark\:focus\:bg-accent:focus) {
	background-color: #5f5af6;
}
:is(.dark .dark\:focus\:bg-accent-focus:focus) {
	background-color: #4d47f5;
}
:is(.dark .dark\:focus\:bg-accent-light\/15:focus) {
	background-color: rgba(129, 140, 248, 0.15);
}
:is(.dark .dark\:focus\:bg-accent-light\/20:focus) {
	background-color: rgba(129, 140, 248, 0.2);
}
:is(.dark .dark\:focus\:bg-navy-300\/20:focus) {
	background-color: rgba(105, 122, 155, 0.2);
}
:is(.dark .dark\:focus\:bg-navy-450:focus) {
	background-color: #465675;
}
:is(.dark .dark\:focus\:bg-navy-500:focus) {
	background-color: #384766;
}
:is(.dark .dark\:focus\:bg-navy-600:focus) {
	background-color: #313e59;
}
:is(.dark .dark\:focus\:bg-navy-900:focus) {
	background-color: #192132;
}
:is(.dark .dark\:focus\:bg-secondary:focus) {
	background-color: #f000b9;
}
:is(.dark .dark\:focus\:bg-secondary-light\/20:focus) {
	background-color: rgba(255, 87, 216, 0.2);
}
:is(.dark .dark\:focus\:bg-white\/30:focus) {
	background-color: hsla(0, 0%, 100%, 0.3);
}
:is(.dark .dark\:focus\:text-accent:focus) {
	color: #5f5af6;
}
:is(.dark .dark\:focus\:text-accent-light:focus) {
	color: #818cf8;
}
:is(.dark .dark\:focus\:text-accent-light\/70:focus) {
	color: rgba(129, 140, 248, 0.7);
}
:is(.dark .dark\:focus\:text-navy-100:focus) {
	color: #c2c9d6;
}
:is(.dark .dark\:focus\:text-secondary-light:focus) {
	color: #ff57d8;
}
:is(.dark .dark\:focus\:text-white:focus) {
	color: #fff;
}
:is(.dark .dark\:focus\:shadow-accent\/50:focus) {
	--tw-shadow-color: rgba(95, 90, 246, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
:is(.dark .dark\:focus\:shadow-navy-450\/50:focus) {
	--tw-shadow-color: rgba(70, 86, 117, 0.5);
	--tw-shadow: var(--tw-shadow-colored);
}
:is(.dark .dark\:active\:bg-accent-focus\/90:active) {
	background-color: rgba(77, 71, 245, 0.9);
}
:is(.dark .dark\:active\:bg-accent-light\/25:active) {
	background-color: rgba(129, 140, 248, 0.25);
}
:is(.dark .dark\:active\:bg-accent\/90:active) {
	background-color: rgba(95, 90, 246, 0.9);
}
:is(.dark .dark\:active\:bg-navy-300\/25:active) {
	background-color: rgba(105, 122, 155, 0.25);
}
:is(.dark .dark\:active\:bg-navy-450\/90:active) {
	background-color: rgba(70, 86, 117, 0.9);
}
:is(.dark .dark\:active\:bg-navy-500\/90:active) {
	background-color: rgba(56, 71, 102, 0.9);
}
:is(.dark .dark\:active\:bg-secondary-light\/25:active) {
	background-color: rgba(255, 87, 216, 0.25);
}
:is(.dark .dark\:active\:bg-secondary\/90:active) {
	background-color: rgba(240, 0, 185, 0.9);
}
:is(.dark .dark\:disabled\:border-navy-450:disabled) {
	border-color: #465675;
}
:is(.dark .dark\:disabled\:bg-navy-600:disabled) {
	background-color: #313e59;
}
:is(.dark .group:hover .dark\:group-hover\:text-accent) {
	color: #5f5af6;
}
:is(.dark .group:hover .dark\:group-hover\:text-accent-light) {
	color: #818cf8;
}
:is(.dark .group:hover .dark\:group-hover\:text-navy-200) {
	color: #a3adc2;
}
:is(.dark .group:focus .dark\:group-focus\:text-accent) {
	color: #5f5af6;
}
:is(.dark .group:focus .dark\:group-focus\:text-accent-light) {
	color: #818cf8;
}
:is(.dark .group:focus .dark\:group-focus\:text-navy-200) {
	color: #a3adc2;
}
:is(.dark .peer:focus ~ .dark\:peer-focus\:text-accent) {
	color: #5f5af6;
}
@media print {
	.print\:hidden {
		display: none;
	}
}
@media (min-width: 640px) {
	.sm\:order-first {
		order: -9999;
	}
	.sm\:order-last {
		order: 9999;
	}
	.sm\:order-none {
		order: 0;
	}
	.sm\:col-span-2 {
		grid-column: span 2 / span 2;
	}
	.sm\:col-span-4 {
		grid-column: span 4 / span 4;
	}
	.sm\:col-span-5 {
		grid-column: span 5 / span 5;
	}
	.sm\:col-span-6 {
		grid-column: span 6 / span 6;
	}
	.sm\:col-span-7 {
		grid-column: span 7 / span 7;
	}
	.sm\:col-span-8 {
		grid-column: span 8 / span 8;
	}
	.sm\:m-0 {
		margin: 0;
	}
	.sm\:mx-5 {
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	}
	.sm\:-mr-1 {
		margin-right: -0.25rem;
	}
	.sm\:-mr-1\.5 {
		margin-right: -0.375rem;
	}
	.sm\:ml-10 {
		margin-left: 2.5rem;
	}
	.sm\:mr-10 {
		margin-right: 2.5rem;
	}
	.sm\:mt-0 {
		margin-top: 0;
	}
	.sm\:mt-2 {
		margin-top: 0.5rem;
	}
	.sm\:mt-5 {
		margin-top: 1.25rem;
	}
	.sm\:block {
		display: block;
	}
	.sm\:flex {
		display: flex;
	}
	.sm\:inline-flex {
		display: inline-flex;
	}
	.sm\:hidden {
		display: none;
	}
	.sm\:h-16 {
		height: 4rem;
	}
	.sm\:h-5 {
		height: 1.25rem;
	}
	.sm\:h-8 {
		height: 2rem;
	}
	.sm\:h-9 {
		height: 2.25rem;
	}
	.sm\:w-5 {
		width: 1.25rem;
	}
	.sm\:w-72 {
		width: 18rem;
	}
	.sm\:w-8 {
		width: 2rem;
	}
	.sm\:w-80 {
		width: 20rem;
	}
	.sm\:w-9 {
		width: 2.25rem;
	}
	.sm\:flex-initial {
		flex: 0 1 auto;
	}
	.sm\:grid-cols-12 {
		grid-template-columns: repeat(12, minmax(0, 1fr));
	}
	.sm\:grid-cols-2 {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	.sm\:grid-cols-3 {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	.sm\:grid-cols-4 {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	.sm\:flex-row {
		flex-direction: row;
	}
	.sm\:flex-col {
		flex-direction: column;
	}
	.sm\:items-start {
		align-items: flex-start;
	}
	.sm\:items-center {
		align-items: center;
	}
	.sm\:justify-start {
		justify-content: flex-start;
	}
	.sm\:justify-between {
		justify-content: space-between;
	}
	.sm\:gap-5 {
		gap: 1.25rem;
	}
	.sm\:gap-x-5 {
		column-gap: 1.25rem;
	}
	.sm\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-left: calc(0.75rem * (1 - var(--tw-space-x-reverse)));
		margin-right: calc(0.75rem * var(--tw-space-x-reverse));
	}
	.sm\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-left: calc(1.25rem * (1 - var(--tw-space-x-reverse)));
		margin-right: calc(1.25rem * var(--tw-space-x-reverse));
	}
	.sm\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-left: calc(1.75rem * (1 - var(--tw-space-x-reverse)));
		margin-right: calc(1.75rem * var(--tw-space-x-reverse));
	}
	.sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-y-reverse: 0;
		margin-bottom: calc(0px * var(--tw-space-y-reverse));
		margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
	}
	.sm\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-y-reverse: 0;
		margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
		margin-top: calc(1.25rem * (1 - var(--tw-space-y-reverse)));
	}
	.sm\:divide-x > :not([hidden]) ~ :not([hidden]) {
		--tw-divide-x-reverse: 0;
		border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
		border-right-width: calc(1px * var(--tw-divide-x-reverse));
	}
	.sm\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
		--tw-divide-y-reverse: 0;
		border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
		border-top-width: calc(0px * (1 - var(--tw-divide-y-reverse)));
	}
	.sm\:p-5 {
		padding: 1.25rem;
	}
	.sm\:px-12 {
		padding-left: 3rem;
		padding-right: 3rem;
	}
	.sm\:px-18 {
		padding-left: 4.5rem;
		padding-right: 4.5rem;
	}
	.sm\:px-5 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}
	.sm\:py-6 {
		padding-bottom: 1.5rem;
		padding-top: 1.5rem;
	}
	.sm\:pb-0 {
		padding-bottom: 0;
	}
	.sm\:pl-0 {
		padding-left: 0;
	}
	.sm\:pl-2 {
		padding-left: 0.5rem;
	}
	.sm\:pl-8 {
		padding-left: 2rem;
	}
	.sm\:text-left {
		text-align: left;
	}
	.sm\:text-right {
		text-align: right;
	}
}
@media (min-width: 1024px) {
	.lg\:static {
		position: static;
	}
	.lg\:sticky {
		position: sticky;
	}
	.lg\:bottom-0 {
		bottom: 0;
	}
	.lg\:order-last {
		order: 9999;
	}
	.lg\:order-none {
		order: 0;
	}
	.lg\:col-span-1 {
		grid-column: span 1 / span 1;
	}
	.lg\:col-span-2 {
		grid-column: span 2 / span 2;
	}
	.lg\:col-span-3 {
		grid-column: span 3 / span 3;
	}
	.lg\:col-span-4 {
		grid-column: span 4 / span 4;
	}
	.lg\:col-span-5 {
		grid-column: span 5 / span 5;
	}
	.lg\:col-span-6 {
		grid-column: span 6 / span 6;
	}
	.lg\:col-span-7 {
		grid-column: span 7 / span 7;
	}
	.lg\:col-span-8 {
		grid-column: span 8 / span 8;
	}
	.lg\:col-span-9 {
		grid-column: span 9 / span 9;
	}
	.lg\:mr-80 {
		margin-right: 20rem;
	}
	.lg\:mt-0 {
		margin-top: 0;
	}
	.lg\:mt-10 {
		margin-top: 2.5rem;
	}
	.lg\:mt-6 {
		margin-top: 1.5rem;
	}
	.lg\:block {
		display: block;
	}
	.lg\:flex {
		display: flex;
	}
	.lg\:inline-flex {
		display: inline-flex;
	}
	.lg\:grid {
		display: grid;
	}
	.lg\:hidden {
		display: none;
	}
	.lg\:h-12 {
		height: 3rem;
	}
	.lg\:h-48 {
		height: 12rem;
	}
	.lg\:h-auto {
		height: auto;
	}
	.lg\:w-12 {
		width: 3rem;
	}
	.lg\:w-48 {
		width: 12rem;
	}
	.lg\:w-80 {
		width: 20rem;
	}
	.lg\:max-w-md {
		max-width: 28rem;
	}
	.lg\:shrink-0 {
		flex-shrink: 0;
	}
	.lg\:grid-cols-1 {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	.lg\:grid-cols-2 {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	.lg\:grid-cols-3 {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	.lg\:grid-cols-4 {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	.lg\:flex-row {
		flex-direction: row;
	}
	.lg\:place-items-center {
		place-items: center;
	}
	.lg\:gap-24 {
		gap: 6rem;
	}
	.lg\:gap-6 {
		gap: 1.5rem;
	}
	.lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
		margin-right: calc(1rem * var(--tw-space-x-reverse));
	}
	.lg\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-left: calc(1.5rem * (1 - var(--tw-space-x-reverse)));
		margin-right: calc(1.5rem * var(--tw-space-x-reverse));
	}
	.lg\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
		--tw-space-y-reverse: 0;
		margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
		margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
	}
	.lg\:self-end {
		align-self: flex-end;
	}
	.lg\:rounded-l-lg {
		border-bottom-left-radius: 0.5rem;
		border-top-left-radius: 0.5rem;
	}
	.lg\:rounded-t-none {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	.lg\:p-6 {
		padding: 1.5rem;
	}
	.lg\:p-7 {
		padding: 1.75rem;
	}
	.lg\:px-12 {
		padding-left: 3rem;
		padding-right: 3rem;
	}
	.lg\:px-5 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}
	.lg\:py-3 {
		padding-bottom: 0.75rem;
		padding-top: 0.75rem;
	}
	.lg\:py-6 {
		padding-top: 1.5rem;
	}
	.lg\:pb-6,
	.lg\:py-6 {
		padding-bottom: 1.5rem;
	}
	.lg\:pl-0 {
		padding-left: 0;
	}
	.lg\:pl-9 {
		padding-left: 2.25rem;
	}
	.lg\:pr-0 {
		padding-right: 0;
	}
	.lg\:text-left {
		text-align: left;
	}
	.lg\:text-2xl {
		font-size: 1.5rem;
		line-height: 2rem;
	}
	.lg\:text-3xl {
		font-size: 1.875rem;
		line-height: 2.25rem;
	}
	.lg\:text-base {
		font-size: 1rem;
		line-height: 1.5rem;
	}
	.lg\:text-lg {
		font-size: 1.125rem;
	}
	.lg\:text-lg,
	.lg\:text-xl {
		line-height: 1.75rem;
	}
	.lg\:text-xl {
		font-size: 1.25rem;
	}
	.lg\:opacity-100 {
		opacity: 1;
	}
}
@media (min-width: 1280px) {
	.xl\:col-span-3 {
		grid-column: span 3 / span 3;
	}
	.xl\:col-span-5 {
		grid-column: span 5 / span 5;
	}
	.xl\:col-span-6 {
		grid-column: span 6 / span 6;
	}
	.xl\:col-span-7 {
		grid-column: span 7 / span 7;
	}
	.xl\:col-span-9 {
		grid-column: span 9 / span 9;
	}
	.xl\:hidden {
		display: none;
	}
	.xl\:grid-cols-3 {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	.xl\:grid-cols-4 {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}
.\[\&\.is-active\>\.tree-header\>\.ac-trigger\>\.ac-icon\]\:rotate-90.is-active
	> .tree-header
	> .ac-trigger
	> .ac-icon {
	--tw-rotate: 90deg;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\.is-active\>\.tree-header\]\:text-slate-800.is-active > .tree-header {
	color: #1e293b;
}
:is(
		.dark
			.dark\:\[\&\.is-active\>\.tree-header\]\:text-navy-100.is-active
			> .tree-header
	) {
	color: #c2c9d6;
}
.\[\&\.is-active_\.ac-header_\.ac-icon\]\:rotate-180.is-active
	.ac-header
	.ac-icon {
	--tw-rotate: 180deg;
}
.\[\&\.is-active_\.ac-header_\.ac-icon\]\:rotate-180.is-active
	.ac-header
	.ac-icon,
.\[\&\.is-active_\.ac-icon\]\:-rotate-180.is-active .ac-icon {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\.is-active_\.ac-icon\]\:-rotate-180.is-active .ac-icon {
	--tw-rotate: -180deg;
}
.\[\&\.is-active_\.ac-icon\]\:rotate-180.is-active .ac-icon {
	--tw-rotate: 180deg;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\.is-active_\.ac-trigger\]\:font-semibold.is-active .ac-trigger {
	font-weight: 600;
}
.\[\&\.is-active_\.ac-trigger\]\:text-slate-800.is-active .ac-trigger {
	color: #1e293b;
}
:is(
		.dark
			.dark\:\[\&\.is-active_\.ac-trigger\]\:text-navy-50.is-active
			.ac-trigger
	) {
	color: #e7e9ef;
}
.\[\&\.is-active_svg\]\:rotate-180.is-active svg {
	--tw-rotate: 180deg;
}
.\[\&\.is-active_svg\]\:rotate-180.is-active svg,
.\[\&\.is-active_svg\]\:rotate-90.is-active svg {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y))
		rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
		scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\.is-active_svg\]\:rotate-90.is-active svg {
	--tw-rotate: 90deg;
}
.\[\&_svg\:first-child\]\:hidden svg:first-child,
.\[\&_svg\:last-child\]\:hidden svg:last-child {
	display: none;
}

table.is-hoverable > tbody > tr:hover > td,
table.is-zebra > tbody > tr:nth-child(2n) > td {
	background-color: #f1f5f9;
}
:is(.dark table.is-hoverable > tbody > tr:hover > td),
:is(.dark table.is-zebra > tbody > tr:nth-child(2n) > td) {
	background-color: #313e59;
}
.pagination {
	color: #475569;
	display: flex;
	font-family: inherit;
	font-weight: 500;
}
:is(.dark .pagination) {
	color: #c2c9d6;
}
.nested-sortable>.list-group-item {
    padding-left: 0.75rem;
    background-color: rgba(243, 244, 246, 1);
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}
.dark .nested-sortable>.list-group-item {
    background-color: #192132;
}
.nested-sortable>.list-group-item .sub-group {
    background-color: rgba(229, 231, 235, 1);
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.nested-sortable>.list-group-item .sub-group:empty {
    margin-top: 0;
    margin-bottom: 0;
}
.nested-sortable>.list-group-item .sub-group .list-group-item {
    padding-top: 0;
    padding-left: 0.75rem;
    padding-bottom: 0;
    padding-right: 0;
}
.dark .nested-sortable>.list-group-item .sub-group {
    background-color: #26334d;
}